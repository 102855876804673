import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import done from "../../../../imgs/optionBranch.svg";
const SuccessModal = ({ setShow }) => {
  const PlaceOrderCheck = useSelector((state) => state.PlaceOrderCheck);
  const router = useNavigate();
  return (
    <div
      className={`w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
    >
      <div className="relative rounded-xl w-[25rem] flex items-center p-6 bg-white flex-col">
        <button
          onClick={() => setShow(false)}
          type="button"
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center "
          data-modal-toggle="select-modal"
        >
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">Close modal</span>
        </button>
        <img src={done} alt="done" className="w-20 h-20" />

        <p className="text-center  mb-4 mt-4 text-[1rem]  px-6">
          We have multiple branches for this company.Do you want to place order
          with company's headquarters or with one of their branches?
        </p>
        {/* <p className="text-center  mb-5 text-[1rem]  px-6">
          Select Yes to proceed with headquarters or No to place it with one of
          their branches.
        </p> */}
        <div className="flex items-center justify-center gap-2">
          <button
            onClick={() => {
              setShow(false);
              router("/SuperAdmin/Inventory/SelectBranch");
            }}
            className="cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem] text-center text-white bg-red-600 w-[9rem]"
          >
            BRANCHES
          </button>
          <button
            onClick={() => {
              setShow(false);
              // router("/Inventory-Products");
              router("/Inventory")
            }}
            className="cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem]  text-white bg-primary w-[9rem]"
          >
            COMPANY HQ
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
