import React, { useEffect } from "react";
import vp1 from "../../../../../imgs/vp1.svg";
import vp2 from "../../../../../imgs/vp2.svg";
import awaited from "../../../../../imgs/awaited.svg";
import quotationNew from "../../../../../imgs/quotationNew.svg";
import allOrders from "../../../../../imgs/allOrders.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const SelectedProgram = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const selectedProgram = useSelector((state) => state.selectedProgram);
  const privilege = useSelector((state) => state.myPrivileges);
  const user = useSelector((state) => state.user);
  const role = useSelector((state) => state.role);

  useEffect(() => {
    dispatch({
      type: "PS_ORDERS",
      num: null,
    });
  }, []);
  const Card = ({
    icon,
    title,
    description,
    bgColor,
    circleColor,
    route,
    value,
  }) => {
    return (
      <div
        className={`flex flex-col items-center p-6 rounded-lg shadow-md ${bgColor} w-auto cursor-pointer`}
        onClick={() => {
          dispatch({
            type: "PS_ORDERS",
            num: {
              value: value,
              title: title,
            },
          });
          router(route);
        }}
      >
        <div
          className={`w-14 h-14 mb-4 ${circleColor} rounded-full flex items-center justify-center p-3`}
        >
          <img src={icon} alt={title} className="w-8 h-8" />
        </div>
        <h3 className="text-lg font-semibold mb-2 text-center">{title}</h3>
        <p className="text-center text-gray-600">{description}</p>
      </div>
    );
  };
  const formatProgramNames = (data) => {
    return data
      .replace(/-/g, " ") // Replace hyphens with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter after space or hyphen
  };
  return (
    <div className="flex justify-center">
      <div
        className={`grid  p-6 py-20  ${
          user === "employee" && !privilege.includes("View priority")
            ? "md:grid-cols-4 sm:grid-cols-2 grid-cols-1  gap-5"
            : `md:grid-cols-4 sm:grid-cols-2 grid-cols-1   gap-5`
        }`}
      >
        {user === "employee" && !privilege.includes("View orders") ? null : (
          <Card
            icon={allOrders}
            value={"all-orders"}
            // title="Approved Orders"
            title={"All Orders"}
            // description="View all approved orders and track their current status."
            description="Orders submitted to the vendor after approval."
            bgColor="bg-[#F1FFFE]"
            circleColor="bg-[#1BA397]"
            route="/Inventory-Vendor-Orders"
          />
        )}

        <Card
          icon={vp2}
          value={"my-orders"}
          // title="Approved Orders"
          title={"My Orders"}
          // description="View all approved orders and track their current status."
          description="View details of orders submitted by you"
          bgColor="bg-[#EAF0FA]"
          circleColor="bg-[#2758A7]"
          route="/Inventory-Vendor-Orders"
        />

        {(user === "employee" && role === "Permanent Secretary") ||
          (user === "superadmin" && (
            <Card
              icon={awaited}
              title="Approval Awaited"
              value={"awaited-ps"}
              description="Review and approve pending orders."
              bgColor="bg-[#FCEFEB]"
              circleColor="bg-[#F35929]"
              route={"/Inventory-Vendor-Orders"}
            />
          ))}
        {/* <Card
          icon={vp2}
          title="Employee- Submitted"
          description="Review employee self submitted orders and their current status."
          bgColor="bg-[#EAF0FA]"
          circleColor="bg-[#2758A7]"
          value={"submitted"}
          route={"/Inventory-Vendor-Orders"}
        /> */}

        {(user === "employee" && role === "Permanent Secretary") ||
          (user === "superadmin" && (
            <Card
              icon={quotationNew}
              title="All Quotations"
              description="View your Orders submitted as quotations."
              bgColor="bg-[#F5ECCD]"
              circleColor="bg-[#F3C729]"
              value={"all-quotations"}
              route={"/Inventory/Quotations"}
            />
          ))}

        <Card
          icon={quotationNew}
          title="My Quotations"
          description="Orders submitted as quotations."
          bgColor="bg-[#F5ECCD]"
          circleColor="bg-[#F3C729]"
          value={"Quotations"}
          route={"/Inventory/Quotations"}
        />
      </div>
    </div>
  );
};

export default SelectedProgram;
