import back5 from "../../../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import Nav from "../../../vendor/navbar";

// import Table from "./table";
import BranchMain from "./main";
import { useSelector } from "react-redux";
import { useState } from "react";
const Listing = () => {
  const router = useNavigate();
  const user = useSelector((state) => state.user);
  const [search, setSearch] = useState("");

  return (
    <>
      <div className="bg-[#D9EFED] min-h-screen h-screen">
        <Nav />
        <div className="flex items-center justify-center bg-[#D9EFED] ">
          <div className="sm:w-[95%] w-full  sm:px-10 px-6 rounded-md mb-12">
            <div className="cursor-pointer flex w-full items-center justify-center">
              <img
                onClick={() => {
                  if (user === "vendor-owner") {
                    router("/VendorDashboard");
                    return;
                  }
                  router("/SuperAdmin/Inventory/SelectVendor");
                }}
                className="sm:w-[1.7rem] w-[1.3rem]"
                src={back5}
                alt=""
              />
              <h2 className="font-bold w-full sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                Select Branch
              </h2>
            </div>
            {/* {user === "superadmin" && (
              <div className="flex justify-end mt-4 gap-3 bg-red-900">
                <div className="flex items-center bg-[#f9fbfb] border border-gray-300 rounded-md px-4 py-2 w-[300px] shadow-sm">
                  <input
                    type="text"
                    placeholder="Search Branch"
                    className="bg-transparent focus:outline-none text-gray-500 placeholder-gray-400 flex-grow"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-[#1BA397]"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2.8"
                      d="M11 19a8 8 0 100-16 8 8 0 000 16zm5-1l5 5"
                    />
                  </svg>
                </div>
              </div>
            )} */}
            <div className="rounded-md sm:p-2  ">
              <BranchMain search={search} />
              {/* <Table search={search} /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Listing;
