import axios from "axios";
import { useState, useEffect } from "react";
import { FiFilter } from "react-icons/fi";
import { MdKeyboardArrowDown, MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ErrorModal from "../../../../Merchant/Modals/errorModal";
import { Avatar } from "antd";
let products = [];

const ProductFilters = ({
  search,
  setSearch,
  setCategoriesFilter,
  categoriesFilter,
}) => {
  const user = useSelector((state) => state.user);
  const SelectedCompanyVendor = useSelector(
    (state) => state.SelectedCompanyVendor
  );

  const SelectedBranchVendor = useSelector(
    (state) => state.SelectedBranchVendor
  );
  const image = SelectedBranchVendor
    ? SelectedBranchVendor?.logo
    : SelectedCompanyVendor?.logo;
  const name = SelectedBranchVendor
    ? SelectedBranchVendor?.name
    : SelectedCompanyVendor?.name;
  const companyId = SelectedCompanyVendor?.id;
  const token = useSelector((state) => state.token);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [err, setErr] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [categories, setCategories] = useState([]);

  const loc = useLocation();
  const dash = loc.pathname;

  const setSelectedFilter = (e) => {
    const value = e.target.value;
    // If checkbox is checked, add to selectedFilters, else remove it
    if (e.target.checked) {
      setSelectedFilters((prev) => [...prev, value]);
    } else {
      setSelectedFilters((prev) => prev.filter((item) => item !== value));
    }
  };

  useEffect(() => {
    filterItems();
  }, [selectedCategory, selectedProduct]);

  const filterItems = () => {
    let filtered = [...products];

    if (selectedCategory) {
      filtered = filtered.filter(
        (product) => product.category === selectedCategory
      );
    }

    if (selectedProduct) {
      filtered = filtered.filter((product) =>
        product.name.toLowerCase().includes(selectedProduct.toLowerCase())
      );
    }

    setFilteredItems(filtered);
  };

  const clearFilters = () => {
    setIsFilterOpen(false);
  };

  //get Categories
  useEffect(() => {
    if (user === "vendor-owner") {
      return;
    }
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/inv-category/list-for-buyer`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        let wow = [];

        data.data.rows.map((item, index) => {
          wow.push({
            value: item?.id,
            label: item?.name,
          });
        });
        setCategories(wow);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShowErrorModal(true);
      });
  }, []);

  return (
    <div className="max-w-7xl mx-auto">
      <div className="relative">
        <div
          className={`${
            dash === "/Inventory" 
              ? "flex sm:justify-between sm:items-center gap-3 sm:flex-row flex-col"
              : "flex justify-end items-center gap-3"
          }`}
        >
          <div
            className={`${
              dash === "/Inventory" 
                ? "flex items-center"
                : "hidden"
            }`}
          >
            <Avatar
              src={image}
              className="border-2 border-primary mr-2 w-12 h-12"
            />
            <p className="text-gray-700 capitalize text-[1rem] font-semibold">
              {name}
            </p>
          </div>
          <div className="flex items-center space-x-2">
            <div
              className={`${
                dash === "/Inventory" || dash === "/Inventory/SelectProduct"
                  ? "flex items-center space-x-2"
                  : "hidden"
              }`}
            >
              <button
                onClick={() => {
                  setSelectedOption(null);
                  setIsFilterOpen(!isFilterOpen);
                }}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md bg-white text-sm font-medium text-gray-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <FiFilter className="mr-2 h-5 w-5" />
                Filter By
              </button>
            </div>
            <div className="flex items-center bg-[#f9fbfb] border border-gray-300 rounded-md px-4 py-2 sm:w-[300px] w-[260px] shadow-sm">
              <input
                type="text"
                placeholder={`${
                  dash === "/Inventory" || dash === "/Inventory/SelectProduct"
                    ? "Search Product"
                    : "Search Vendor"
                }`}
                className="bg-transparent focus:outline-none text-gray-500 placeholder-gray-400 flex-grow"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-[#1BA397]"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.8"
                  d="M11 19a8 8 0 100-16 8 8 0 000 16zm5-1l5 5"
                />
              </svg>
            </div>
          </div>
        </div>

        <div class="filter-container flex flex-wrap gap-2 py-3">
          {categoriesFilter?.map((filter) => (
            <div
              class="selected-filter bg-gray-100 text-gray-800 px-4 py-2 rounded-full flex items-center gap-2 border border-red-500"
              id="filter1"
            >
              <span class="filter-text text-sm font-medium">
                {categories?.find((item) => item?.value === filter)?.label}
              </span>
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={() => {
                  setCategoriesFilter((prev) =>
                    prev.filter((item) => item !== filter)
                  );
                  setSelectedFilters((prev) =>
                    prev.filter((item) => item !== filter)
                  );
                }}
              >
                <MdClose className="h-5 w-5" />
              </button>
            </div>
          ))}
        </div>

        {/* {selectedFilters?.map((filter) => (
          <div className="mt-4">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-medium text-gray-800">{filter}</h3>
              <button className="text-gray-500 hover:text-gray-700">
                <MdClose className="h-5 w-5" />
              </button>
            </div>
          </div>
        ))} */}

        {/* {isFilterOpen && (
          <div className="absolute top-16 right-4 w-64 bg-white rounded-lg shadow-xl border border-gray-200 p-4 z-50">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium text-gray-800">Filter Options</h3>
              <button onClick={clearFilters} className="text-gray-500 hover:text-gray-700">
                <MdClose className="h-5 w-5" />
              </button>
            </div>
            <div className="space-y-4">
              <div>
                <h4 className="text-sm font-medium text-gray-700 mb-2">By Category</h4>
                <div className="grid grid-cols-2 gap-2">
                  {categories.map((category) => (
                    <button
                      key={category}
                      onClick={() => setSelectedCategory(category)}
                      className={`px-3 py-1.5 rounded-md text-xs ${selectedCategory === category
                        ? "bg-blue-500 text-white"
                        : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                        }`}
                    >
                      {category}
                    </button>
                  ))}
                </div>
              </div>
              <div>
                <h4 className="text-sm font-medium text-gray-700 mb-2">By Product</h4>
                <div className="space-y-2">
                  {products.map((product) => (
                    <button
                      key={product.id}
                      onClick={() => setSelectedProduct(product.name)}
                      className={`w-full p-2 rounded-md text-left text-sm ${selectedProduct === product.name
                        ? "bg-blue-500 text-white"
                        : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                        }`}
                    >
                      <div className="font-medium">{product.name}</div>
                      <div className="text-xs opacity-75">{product.category}</div>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )} */}

        {isFilterOpen && (
          <div className="absolute top-14 right-1 w-full bg-white rounded-lg shadow-xl border border-gray-200 p-4 z-50">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium text-gray-800">Filter By</h3>
              <h2 className="text-[1rem] font-medium text-gray-600">
                {selectedOption}
              </h2>
              <button
                onClick={() => {
                  setIsFilterOpen(false);
                }}
                className="text-gray-500 hover:text-gray-700"
              >
                <MdClose className="h-5 w-5" />
              </button>
            </div>
            <div className="flex gap-3 ">
              <div className="w-1/3">
                <div className="border-b border-gray-200 py-4">
                  <h3 className="-my-3 flow-root">
                    <button
                      type="button"
                      className={`flex w-full items-center justify-between py-3 text-sm ${
                        selectedOption === "Category"
                          ? "text-primary bg-gray-50 px-2"
                          : "text-gray-400 hover:text-gray-500 bg-white"
                      }`}
                      aria-controls="filter-section-1"
                      aria-expanded="false"
                      onMouseEnter={() => setSelectedOption("Category")}
                    >
                      <span className="font-medium text-[1rem]">Category</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </button>
                  </h3>
                </div>
                {/* <div className="border-b border-gray-200 py-4">
                  <h3 className="-my-3 flow-root">
                    <button
                      type="button"
                      className={`flex w-full items-center justify-between  py-3 text-sm ${
                        selectedOption === "Products"
                          ? "text-primary bg-gray-50 px-2"
                          : "text-gray-400 hover:text-gray-500 bg-white "
                      }`}
                      aria-controls="filter-section-1"
                      aria-expanded="false"
                      onMouseEnter={() => setSelectedOption("Products")}
                    >
                      <span className="font-medium text-[1rem]">Products</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </button>
                  </h3>
                </div> */}
              </div>

              <div className="w-2/3 bg-gray-50 p-4 overflow-y-scroll h-[20rem] ">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  {selectedOption &&
                    (selectedOption === "Category"
                      ? categories?.map((item, index) => (
                          <div className="flex items-center" key={index}>
                            <input
                              id={`filter-category-${index}`}
                              name="category[]"
                              value={item?.value}
                              type="checkbox"
                              className="custom-checkbox h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              onChange={setSelectedFilter}
                              checked={selectedFilters?.includes(item?.value)}
                            />
                            <label
                              htmlFor={`filter-category-${index}`}
                              className="ml-3 text-sm text-gray-600"
                            >
                              {item?.label}
                            </label>
                          </div>
                        ))
                      : products?.map((item, index) => (
                          <div className="flex items-center" key={index}>
                            <input
                              id={`filter-other-${index}`}
                              name="other[]"
                              value={item}
                              type="checkbox"
                              className="custom-checkbox h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              onChange={setSelectedFilter}
                            />
                            <label
                              htmlFor={`filter-other-${index}`}
                              className="ml-3 text-sm text-gray-600"
                            >
                              {item}
                            </label>
                          </div>
                        )))}
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end gap-3 mt-3">
              <button
                className="cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[10rem]"
                onClick={() => {
                  if (selectedFilters?.length > 0) {
                    setCategoriesFilter((prevFilters) => {
                      // Deduplicate selectedFilters first
                      const uniqueSelectedFilters = [
                        ...new Set(selectedFilters),
                      ];

                      // Filter uniqueSelectedFilters to include only values not already in prevFilters
                      const newFilters = uniqueSelectedFilters?.filter(
                        (value) => !prevFilters?.includes(value)
                      );

                      return [...prevFilters, ...newFilters];
                    });
                  }

                  setIsFilterOpen(false);
                }}
              >
                Apply Filter
              </button>
              <button
                className="cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-red-600 w-[10rem]"
                onClick={() => {
                  setSelectedFilters([]);
                }}
              >
                Clear All
              </button>
            </div>
          </div>
        )}
      </div>

      {/* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {(filteredItems.length > 0 ? filteredItems : products).map((product) => (
          <div
            key={product.id}
            className="bg-white rounded-lg shadow-md overflow-hidden transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-xl"
          >
            <div className="w-full h-48 overflow-hidden">
              <img
                src="images.unsplash.com/photo-1523275335684-37898b6baf30?ixlib=rb-4.0.3"
                alt={product.name}
                className="w-full h-full object-cover transition-transform duration-300 hover:scale-110"
              />
            </div>
            <div className="p-4">
              <h3 className="text-lg font-semibold text-gray-900">{product.name}</h3>
              <p className="text-sm text-gray-500 mt-1">{product.category}</p>
              <div className="flex justify-between items-center mt-3">
                <p className="text-lg font-bold text-blue-600">${product.price}</p>
                <button className="px-3 py-1 bg-blue-500 text-white text-sm rounded-md hover:bg-blue-600 transition-colors">
                  Add to Cart
                </button>
              </div>
            </div>
          </div>
        ))}
      </div> */}
      {showErrorModal && <ErrorModal err={err} setShow={setShowErrorModal} />}
    </div>
  );
};

export default ProductFilters;
