import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../../../Merchant/Modals/errorModal";
import productSuper from "../../../../imgs/productSuper.svg";
import money from "../../../../imgs/money.svg";

import axios from "axios";
import { Checkbox } from "antd";

const SelectProgram = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const SelectedOrderFlow = useSelector((state) => state.SelectedOrderFlow);
  const [selectedFlow, setselectedFlow] = useState(null);
  const [err, setErr] = useState("");
  const [showErr, setShowErr] = useState(false);

  const options = [
    {
      title: "Search Product",
      icon: productSuper,
      des: "Searching for a specific product and compare prices and options across multiple vendors.",
      route:"/Inventory/SelectProduct"
    },
    {
      title: "Select Vendor",
      icon: money,
      des: "View products from a specific vendor. Best if you prefer a trusted seller",
      route:"/SuperAdmin/Inventory/SelectVendor"
    },
  ];

  return (
    <div className="">
      <div className="flex justify-center">
        <div
          className={`bg-[#fafaf9] shadow-lg rounded-md mx-4 m-5 p-8 sm:w-[95%] w-full`}
        >
          <div className={`grid  grid-cols-1 gap-5 bg-[#F2F0F0] sm:p-14 p-3 `}>
            {options?.map((program, index) => (
              <div
                key={index}
                className={`flex items-start p-3  mb-3  cursor-pointer text-gray-600 sm:w-[50%] w-full
                `}
              >
                {/* Left: Icon */}

                {/* Right: Checkbox, Heading, and Description */}
                <Checkbox
                  checked={selectedFlow === program?.title}
                  onChange={() => {
                    if (selectedFlow === program.title) {
                      setselectedFlow(null);
                      return;
                    }
                    setselectedFlow(program?.title);
                    dispatch({
                      type: "SELECTED_ORDER_FLOW",
                      num: program,
                    });
                  }}
                  className="flex-1 checkk3"
                >
                  <div className="flex flex-row ml-4 gap-5">
                    <div className="flex items-center justify-center  rounded-full mr-3">
                      <img
                        src={program?.icon}
                        alt="alt"
                        className="w-16 h-16"
                      />
                    </div>
                    <div>
                      <h1 className="font-semibold text-[0.98rem] capitalize">
                        {program?.title}
                      </h1>
                      <p className="text-sm text-gray-500 mt-1 font-normal">
                        {program?.des}
                      </p>
                    </div>
                  </div>
                </Checkbox>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className={`mt-3 flex  items-center sm:justify-center justify-center w-full mb-5 `}
      >
        <button
          type="button"
          onClick={() => {
            if (selectedFlow === null) {
              setErr("Please select one of the options.");
              setShowErr(true);
              return;
            }
            router(SelectedOrderFlow?.route);
            // router("/pensionerVerification/selectedProgram");
            // router("/SuperAdmin/Inventory/SelectVendor");
          }}
          className={`lg:text-[1rem] bg-gradient-to-tr  bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[12rem]`}
        >
          Next
        </button>
      </div>
      {showErr && <ErrorModal show={showErr} setShow={setShowErr} err={err} />}
    </div>
  );
};

export default SelectProgram;
