import React, { useState, useEffect } from "react";
import { Avatar, Button, Carousel, Pagination, Rate } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import like from "../../../../imgs/heartF.png";
import dislike from "../../../../imgs/heartD.png";
import cartW from "../../../../imgs/cartW.png";

import Footer from "../../cart/footer";
import axios from "axios";
import Filter from "../../superAdmin/vendor/filters/newFilter";
const EventCard = () => {
  const [debouncedQuantity, setDebouncedQuantity] = useState(""); // Debounced value
  const SelectedCompanyVendor = useSelector(
    (state) => state.SelectedCompanyVendor
  );
  const SelectedBranchVendor = useSelector(
    (state) => state.SelectedBranchVendor
  );

  const companyId = SelectedCompanyVendor?.id;
  const router = useNavigate();
  const dispatch = useDispatch();
  const category = useSelector((state) => state.category);
  const cart = useSelector((state) => state.cartData);
  const role = useSelector((state) => state.role);
  const fav = useSelector((state) => state.fav);
  const [localCart, setCart] = useState(cart);
  const [favorite, setFavorite] = useState(fav);
  const [initial, setinitial] = useState(true);
  const [initialFav, setinitialFav] = useState(true);
  const user = useSelector((state) => state.user);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const token = useSelector((state) => state.token);
  const [data, setData] = useState([]);
  const [showBranch, setShowBranch] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [buyerBranch, setBuyerBranch] = useState(null);
  const [afterapi, setAfterApi] = useState(false);
  const [categoriesFilter, setCategoriesFilter] = useState([]);

  useEffect(() => {
    if (SelectedBranchVendor !== null) {
      setBuyerBranch(SelectedBranchVendor?.id);
    }
  }, []);

  useEffect(() => {
    dispatch({ type: "EDIT_PRODUCT", num: false });
    dispatch({ type: "PRODUCT_ID", num: null });
    dispatch({ type: "QUOTATION_FLOW", num: false });
    dispatch({ type: "SELECTED_VENDORS", num: [] });
    dispatch({ type: "Cart", num: [] });

    // setCart(cart);
  }, []);
  useEffect(() => {
    setFavorite(fav);
  }, [fav]);
  useEffect(() => {
    if (!initial) {
      dispatch({ type: "Cart", num: localCart });
    }
  }, [localCart]);
  useEffect(() => {
    // console.log("fav", favorite);
    if (!initialFav) {
      dispatch({ type: "Fav", num: favorite });
    }
  }, [favorite]);

  const addToCart = (data) => {
    setinitial(false);
    const cartItem = {
      id: data?.id,
      image: data?.invproductgallaries?.find((gallery) => gallery?.isCoverImg)
        ?.image
        ? data?.invproductgallaries?.find((gallery) => gallery?.isCoverImg)
            ?.image
        : "https://t3.ftcdn.net/jpg/03/45/05/92/360_F_345059232_CPieT8RIWOUk4JqBkkWkIETYAkmz2b75.jpg",
      name: data?.name,
      quantity: data?.addedQuantity !== null ? data?.addedQuantity : 1,
      price: parseFloat(data?.basePrice), // Convert to number
      unit: data?.pricingUnit,
      minOrder: data?.minimumOrderQty,
      itemCode: data?.itemCode,
    };

    const updatedCart = [...localCart, cartItem];
    setCart(updatedCart);
  };
  const addToFav = (event) => {
    setinitialFav(false);
    const favItems = {
      id: event.id,
      coverPhotos: event.invproductgallaries,
      name: event.name,
      route: event.route,
      quantity: event.quantity,
      price: event.basePrice,
      amount: event.amount,
      minOrder: event?.minimumOrderQty,
    };

    const updatedFav = [...favorite, favItems];
    setFavorite(updatedFav);
  };

  const changeQuantity = (event) => {
    setinitial(false);
    const updatedCart = localCart.map((item) => {
      if (item.id === event.id) {
        return {
          ...item,
          quantity: event.quantity,
        };
      }
      return item;
    });
    setCart(updatedCart);
  };

  const removeFromCart = (eventId) => {
    setinitial(false);
    const updatedCart = localCart.filter((item) => item.id !== eventId);
    setCart(updatedCart);
  };
  const removeFromFav = (eventId) => {
    setinitialFav(false);
    const updatedFav = favorite.filter((item) => item.id !== eventId);
    setFavorite(updatedFav);
  };

  const isAddedToCart = (eventId) => {
    return localCart.some((item) => item.id === eventId);
  };
  const isAddedToFav = (eventId) => {
    return favorite.some((item) => item.id === eventId);
  };

  const increaseQuantity = (res) => {
    setinitial(false);
    if (isAddedToCart(res?.id)) {
      const updatedCart = localCart.map((item) => {
        if (item.id === res?.id) {
          // Increment the quantity of the existing item
          return { ...item, quantity: item.quantity + 1 };
        }
        return item;
      });

      setData((prevData) =>
        prevData.map((product) =>
          product.id === res?.id
            ? {
                ...product,
                addedQuantity: product?.addedQuantity + 1,
              }
            : product
        )
      );

      setCart(updatedCart);
    } else {
      setData((prevData) =>
        prevData.map((product) =>
          product.id === res?.id
            ? {
                ...product,
                addedQuantity: product?.addedQuantity + 1,
              }
            : product
        )
      );
    }
  };

  const decreaseQuantity = (res) => {
    setinitial(false);
    if (isAddedToCart(res?.id)) {
      const updatedCart = localCart
        .map((item) => {
          if (item.id === res?.id) {
            if (item.quantity > 1) {
              // Decrease the quantity if it's greater than 1
              return { ...item, quantity: item.quantity - 1 };
            }
            // Call the removeCart function for quantity 1
            removeFromCart(item.id);
            return null; // Mark for removal
          }
          return item;
        })
        .filter(Boolean); // Remove null items
      setData((prevData) =>
        prevData.map((product) =>
          product?.id === res?.id
            ? {
                ...product,
                addedQuantity: product?.addedQuantity - 1,
              }
            : product
        )
      );
      setCart(updatedCart);
    } else {
      setData((prevData) =>
        prevData.map((product) =>
          product?.id === res?.id
            ? {
                ...product,
                addedQuantity: product?.addedQuantity - 1,
              }
            : product
        )
      );
    }
  };

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");

  //Get Listing
  useEffect(() => {
    // if (user === "superadmin") {
    //   setData(events);
    //   return;
    // }
    setAfterApi(false);
    const delay = 1000;
    const timeoutId = setTimeout(() => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const vendorParams = {
        limit: 12,
        skip: 0,
        page: page,
        sortBy: "createdAt:desc",
        ...(search && { search }),
        ...(selectedBranch && { vendorbranchId: selectedBranch?.id }),
        ...(category && { invcategoryId: category?.id }),
      };

      const buyerParams = {
        limit: 12,
        skip: 0,
        page: page,
        sortBy: "createdAt:desc",
        ...(search && { search }),
        ...(categoriesFilter?.length > 0 && {
          invcategoryIDs: categoriesFilter,
        }),
        // ...(category && { invcategoryId: category?.id }),
      };

      const params =
        user === "superadmin" || user === "employee"
          ? buyerParams
          : vendorParams;

      fetchAllAccess(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [page, selectedBranch, search, categoriesFilter]);

  const fetchAllAccess = (params, cancelTokenSource) => {
    const api =
      user === "superadmin" || user === "employee"
        ? `v1/inv-product/buyer-list-products`
        : "v1/inv-product/vendor-query-own-products";
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        const modifiedData = res?.data?.rows?.map((item) => ({
          ...item,
          addedQuantity:
            item?.minimumOrderQty !== null ? item?.minimumOrderQty : 1, // Add the new property
        }));
        setData(modifiedData);
        setTotalPages(res?.data?.totalPages);
        setAfterApi(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // console.log(data);

  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <Button
        className="absolute border-none hover:bg-[#1BA397] z-10 h-7 w-7 top-1/2 flex items-center justify-center rounded-full  left-0 transform -translate-y-1/2"
        onClick={onClick}
      >
        <LeftOutlined className="text-white font-semibold" />
      </Button>
    );
  };

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <Button
        className="absolute border-none hover:bg-[#1BA397] z-10 h-7 w-7 top-1/2 flex items-center justify-center rounded-full  right-0 transform -translate-y-1/2"
        onClick={onClick}
      >
        <RightOutlined className="text-white font-semibold" />
      </Button>
    );
  };

  const pageHandler = (e) => {
    setPage(e);
  };

  return (
    <div className="flex w-full  pb-[8rem] flex-col justify-center mt-4  ">
      {user === "superadmin" || user === "employee" ? (
        <div className="w-full">
          <Filter
            search={search}
            setSearch={setSearch}
            setCategoriesFilter={setCategoriesFilter}
            categoriesFilter={categoriesFilter}
          />
        </div>
      ) : null}

      {/* w-full flex flex-wrap md:gap-5 gap-3 gap-y-8 mt-2 justify-center */}
      {data?.length <= 0 && afterapi ? (
        <div className="w-full flex items-center justify-center  bg-white  mt-10 rounded-md">
          <h1 className="text-lg font-semibold text-gray-600 p-32">
            No Products Found
          </h1>
        </div>
      ) : (
        <div className="bg-white p-6">
          <div className="w-full grid grid-cols-1 gap-7 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-2 xl:grid-cols-4">
            {data?.map((event) => (
              <>
                <div class="w-full flex  justify-center   ">
                  <article class="max-w-sm w-full bg-[#F8F6F6] rounded-lg shadow-lg overflow-hidden">
                    <div className="bg-red">
                      <img
                        class="object-fill h-64 w-full p-4 bg-gray-50"
                        src={
                          event?.invproductgallaries?.find(
                            (gallery) => gallery?.isCoverImg
                          )?.image
                            ? event?.invproductgallaries?.find(
                                (gallery) => gallery?.isCoverImg
                              )?.image
                            : "https://t3.ftcdn.net/jpg/03/45/05/92/360_F_345059232_CPieT8RIWOUk4JqBkkWkIETYAkmz2b75.jpg"
                        }
                        alt="Converse sneakers"
                      />
                    </div>

                    <div class="flex flex-col gap-1 mt-1 px-4">
                      <div className="flex items-center justify-between">
                        <h2
                          onClick={() => {
                            if (event?.vendorbranch) {
                              dispatch({
                                type: "SELECTED_BRANCH_VENDOR",
                                num: event?.vendorbranch,
                              });
                              dispatch({
                                type: "SELECTED_COMPANY_VENDOR",
                                num: null,
                              });
                            } else {
                              dispatch({
                                type: "SELECTED_COMPANY_VENDOR",
                                num: event?.vendorcompany,
                              });
                              dispatch({
                                type: "SELECTED_BRANCH_VENDOR",
                                num: null,
                              });
                            }
                            dispatch({ type: "Product", num: event });
                            router("/Inventory/Product/Detail");
                            // router(role === "buyer" ? event?.route : event.routeV);
                          }}
                          class="text-lg font-semibold text-gray-800 hover:text-[#1BA397] cursor-pointer capitalize"
                        >
                          {event.name}
                        </h2>

                        <span class="font-semibold text-gray-800 ">
                          ${event?.basePrice}/{event?.pricingUnit}
                        </span>
                      </div>

                      {user !== "vendor-owner" && (
                        <div className="flex items-center justify-between mb-3">
                          {/* Minimum Order Quantity */}
                          <div className="flex items-center">
                            <span className="text-gray-500 font-semibold mr-1 text-[13px]">
                              Min Order Quantity:
                            </span>
                            <span className="text-red-500 font-semibold">
                              {event?.minimumOrderQty !== null
                                ? event?.minimumOrderQty
                                : 1}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>

                    {user !== "vendor-owner" && (
                      <div className="w-full  px-3 pb-3  ">
                        <div className="p-3 bg-white border-gray-200 rounded-b-lg ">
                          <div className="flex items-center justify-between">
                            <h4>Seller</h4>
                            <div className={`flex items-center`}>
                              <Avatar
                                src={
                                  event?.vendorbranch
                                    ? event?.vendorbranch?.logo
                                    : event?.vendorcompany
                                    ? event?.vendorcompany?.logo
                                    : "https://t3.ftcdn.net/jpg/03/45/05/92/360_F_345059232_CPieT8RIWOUk4JqBkkWkIETYAkmz2b75.jpg"
                                }
                                className="border-2 border-primary mr-2 w-12 h-12"
                              />
                              <div>
                                <p className="text-gray-700 capitalize text-[0.95rem] font-semibold">
                                  {event?.vendorbranch
                                    ? event?.vendorbranch?.name
                                    : event?.vendorcompany
                                    ? event?.vendorcompany?.name
                                    : "------"}
                                </p>
                                <p className={"pointer-events-none"}>
                                  <Rate
                                    style={{ fontSize: "16px" }}
                                    allowHalf
                                    value={
                                      event?.vendorbranch
                                        ? event?.vendorbranch?.rating
                                        : event?.vendorcompany
                                        ? event?.vendorcompany?.rating
                                        : 0
                                    }
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </article>
                </div>
              </>
            ))}
          </div>
          <div className={`flex justify-center mt-7`}>
            <Pagination
              defaultCurrent={1}
              total={totalPages * 10}
              showSizeChanger={false}
              onChange={pageHandler}
              current={page}
            />
          </div>
        </div>
      )}

      {cart?.length > 0 && user !== "vendor-owner" && <Footer />}
    </div>
  );
};

export default EventCard;
