import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import cart from "../../../../imgs/Cart.svg";
import money from "../../../../imgs/money.svg";
import money1 from "../../../../imgs/money1.svg";
import productSuper from "../../../../imgs/productSuper.svg";
import qoutationsDash from "../../../../imgs/qoutationsDash.svg";
import proDash from "../../../../imgs/proDash.svg";
import { useNavigate } from "react-router-dom";

const Card = () => {
  const router = useNavigate();
  const user = useSelector((state) => state.user);
  const role = useSelector((state) => state.role);
  const dispatch = useDispatch();
  const SelectedOrderFlow = useSelector((state) => state.SelectedOrderFlow);
  console.log(SelectedOrderFlow);

  const items = [
    {
      key: "1",
      label: `Orders`,
      img: money1,
      route:
        // user === "employee" && role === "Permanent Secretary"
        // ?
        "/Inventory/PSOrders",
      // : "/Inventory-Vendor-Orders"
      content: "View, place and track orders",
    },
    {
      key: "2",
      label: `Vendors`,
      img: money,
      route: "/SuperAdmin/Inventory/VendorListing",
      content: "Add vendors and manage the vendor list",
    },
    {
      key: "3",
      label: `Products`,
      img: proDash,
      route: "/Inventory/SelectProgram",
      // route: "/Inventory/SelectProduct",
      content:
        "Search specific product and compare prices and options across multiple vendors.",
    },
    {
      key: "4",
      label: `Quotations`,
      img: qoutationsDash,
      route: "/Inventory/Quotations",
      content:
        "Track Inventory Costs by Warehouse, Department, Location and Product",
    },
    {
      key: "5",
      label: `Purchased Products`,
      img: cart,
      route: "/SuperAdmin/Inventory/PurchasedProducts",
      content: "View and track all purchased product details",
    },
    {
      key: "6",
      label: `Inventory Cost`,
      img: productSuper,
      route: "/SuperAdmin/Inventory/InventoryCost",
      content:
        "Track Inventory Costs by Warehouse, Department, Location and Product",
    },
  ];

  return (
    <div>
      {/* Other Cards */}
      <div
        className={`grid   gap-8 sm:px-10 py-10 cursor-pointer ${
          user === "merchant-employee"
            ? "sm:grid-cols-3 grid-cols-1"
            : "sm:grid-cols-3 grid-cols-1"
        }`}
      >
        {items
          .filter((item) => !(item.key === "2" && user === "merchant-employee"))
          .map((item, index) => (
            <div
              key={index}
              className=" p-10 bg-[#D9EFED] rounded-lg shadow-md"
              onClick={() => {
                if (item?.label === "Products") {
                  dispatch({
                    type: "SELECTED_ORDER_FLOW",
                    num: {
                      title: "Search Product",
                      des: "Searching for a specific product and compare prices and options across multiple vendors.",
                      route: "/Inventory/SelectProduct",
                    },
                  });
                }

                if (item?.label === "Quotations") {
                  dispatch({
                    type: "PS_ORDERS",
                    num: {
                      value: "all-quotations",
                      title: "All Quotations",
                    },
                  });
                }
                router(item.route);
              }}
            >
              <div className="flex flex-col justify-center items-center">
                <div className="w-[5rem] h-[5rem] mb-4 overflow-hidden">
                  <img
                    src={item.img}
                    alt={item.label}
                    className="w-full h-full object-fill"
                  />
                </div>
                <div className="text-gray-800 font-bold text-[1.1rem] mb-2 text-center">
                  {item.label}
                </div>
                <p className="text-gray-700 text-center font-semibold">
                  {item.content}
                </p>
              </div>
              {item.text && (
                <p className="text-center font-semibold mt-8 text-primary italic">
                  {item.text}
                </p>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Card;
