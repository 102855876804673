import Ellipse from "../../../imgs/backg.svg";
import Vegetables from "../../../imgs/Vegetables.png";
import Fruits from "../../../imgs/Fruits.png";
import Meat from "../../../imgs/Meat.png";
import Sea from "../../../imgs/Sea.png";
import back5 from "../../../imgs/back5.png";
import Bakery from "../../../imgs/Bakery.png";
import Dairy from "../../../imgs/Dairy.png";
import Juices from "../../../imgs/Juices.png";
import Snacks from "../../../imgs/Snacks.png";
import img from "../../../imgs/impLogo.png";
import pencil from "../../../imgs/pencil.svg";
import { useNavigate } from "react-router";
import { message, Pagination } from "antd";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddCategory from "./addCategory";
import ErrorModal from "../../Merchant/Modals/errorModal";
import SuccessModal from "../../Merchant/Modals/successModal";
import axios from "axios";
let items;
const AdminPanel = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const router = useNavigate();
  const PlaceOrderCheck = useSelector((state) => state.PlaceOrderCheck);
  const token = useSelector((state) => state.token);
  const role = useSelector((state) => state.role);
  const [messageApi, contextHolder] = message.useMessage();
  const [addCategory, setAddCategory] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [err, setErr] = useState("");
  const [name, setName] = useState("");
  const [image, setImage] = useState();
  const [imageUrl, setImageUrl] = useState("");
  const [description, setDescription] = useState("");
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);
  const dataUser = useSelector((state) => state.dataUser);
  const vendorId = dataUser?.data?.vendorUser?.id;
  const [edit, setEdit] = useState(false);
  const [catId, setCatId] = useState("");
  const [search, setSearch] = useState("");

  const pageHandler = (e) => {
    setPage(e);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const Success = () => {
    messageApi.open({
      content: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={img} alt="Your Image" width="50" height="50" />
          <h3>
            This Module is currently in development and will be launched soon.
          </h3>
        </div>
      ),
      duration: 3,
    });
  };

  const items = [
    {
      key: "1",
      name: `Vegetables`,
      image: Vegetables,
      items: "47",
      route: "/Inventory-Products",
    },
    {
      key: "2",
      name: `Fruits`,
      image: Fruits,
      items: "32",
      route: "/Inventory-Products",
    },
    {
      key: "3",
      name: `Meat`,
      image: Meat,
      items: "43",
      route: "/Inventory-Products",
    },
    {
      key: "4",
      name: `Sea Food`,
      image: Sea,
      items: "65",
      route: "/Inventory-Products",
    },
    {
      key: "5",
      name: `Bakery`,
      image: Bakery,
      items: "11",
      route: "/Inventory-Products",
    },
    {
      key: "6",
      name: `Dairy`,
      image: Dairy,
      items: "12",
      route: "/Inventory-Products",
    },
    {
      key: "7",
      name: `Juices`,
      image: Juices,
      items: "55",
      route: "/Inventory-Products",
    },
    {
      key: "8",
      name: `Snacks`,
      image: Snacks,
      items: "56",
      route: "/Inventory-Products",
    },
  ];

  // Reset page to 1 when search changes
  useEffect(() => {
    setPage(1);
  }, [search]);
  //Get Listing
  useEffect(() => {
    if (user === "superadmin") {
      setData(items);
      return;
    }
    const delay = 1000;
    const timeoutId = setTimeout(() => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        limit: 12,
        skip: (page - 1) * 12,
        page: page,
        sortBy: "createdAt:desc",
        ...(search && { search }),
      };

      fetchAllAccess(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [refetch, page, search]);

  const fetchAllAccess = (params, cancelTokenSource) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/inv-category/list-for-vendor`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params,
          cancelToken: cancelTokenSource.token,
        }
      )
      .then((res) => {
        setData(res?.data?.rows);
        setTotalPages(res?.data?.totalPages);
      })
      .catch((err) => {
        // setErr(err?.response?.data?.message);
        // setShowErrorModal(true);
      });
  };

  const AddandUpdateCategory = () => {
    // alert("Add and Update Category")
    // return
    if (name === "") {
      setErr("Please enter category name");
      setShowErrorModal(true);
      return;
    }

    if (image === "") {
      setErr("Please upload category image");
      setShowErrorModal(true);
      return;
    }

    if (description === "") {
      setErr("Please enter category description");
      setShowErrorModal(true);
      return;
    }
    setAddCategory(false);
    const api = edit ? `v1/inv-category/${catId}` : `v1/inv-category`;
    const method = edit ? "put" : "post";
    axios[method](
      `${process.env.REACT_APP_BASE_URL}/${api}`,
      {
        name: name,
        description: description,
        image: imageUrl,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then(() => {
        setRefetch(!refetch);
        setErr(`Category ${edit ? "Updated" : "Added"} Successfully`);
        setShowSuccessModal(true);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShowErrorModal(true);
      });
  };

  return (
    <div>
      <div>
        {contextHolder}
        <div className={`flex items-center justify-center bg-[#D9EFED]`}>
          <div className="md:w-[95%] mt-[1rem] rounded-md w-[95%] sm:px-20 px-4">
            <div className="cursor-pointer flex w-full items-center justify-center">
              <img
                onClick={() => {
                  if (user === "vendor-owner") {
                    router("/VendorDashboard");
                    return;
                  }

                  router("/SuperAdmin/InventoryDashboard");
                }}
                className="sm:w-[1.7rem] w-[1.3rem]"
                src={back5}
                alt=""
              />
              <h2 className="font-bold w-full sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                Categories
              </h2>
            </div>
            <div className="flex justify-end mt-4 gap-3">
              <div className="flex items-center bg-[#f9fbfb] border border-gray-300 rounded-md px-4 py-3 w-[300px] shadow-sm">
                <input
                  type="text"
                  placeholder="Search Category"
                  className="bg-transparent focus:outline-none text-gray-500 placeholder-gray-400 flex-grow"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-[#00b0a6]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M11 19a8 8 0 100-16 8 8 0 000 16zm5-1l5 5"
                  />
                </svg>
              </div>

              {user === "vendor-owner" && (
                <button
                  type="button"
                  onClick={() => {
                    setAddCategory(true);
                    setEdit(false);
                    setName("");
                    setDescription("");
                    setImage("");
                    setImageUrl("");
                    // router("/Inventory-AddProduct");
                  }}
                  className="cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[12rem]"
                >
                  Add Category
                </button>
              )}
            </div>

            <div className="mt-3  rounded-md  p-[4rem] pt-[2rem] mb-16  sm:min-h-[360px] bg-[#F6F4F4] shadow-lg">
              <div className="w-full flex flex-wrap mt-[4.5rem] justify-center items-center gap-8 md:gap-x-8 gap-y-10 sm:min-h-[200px]">
                {data?.map((item, index) => (
                  <div
                    key={index}
                    // onClick={() => {
                    //   if (item.route === "") {
                    //     Success();
                    //   } else {
                    //     if (PlaceOrderCheck) {
                    //       dispatch({ type: "Cat", num: item.name });
                    //       router("/SuperAdmin/Inventory/SelectVendor");
                    //       return;
                    //     }
                    //     dispatch({ type: "Cat", num: item.name });
                    //     router("/Inventory-Products");
                    //   }
                    // }}
                    // hover:bg-[#8BD6B5]
                    className="relative sm:w-[200px] w-[250px] rounded-md mb-[30px] h-[170px] p-2 
                     
                      bg-[#E9F8EE] hover:text-primary custom-shadow
                     "
                  >
                    <div
                      className={` ${
                        item?.vendoruserId === vendorId &&
                        user === "vendor-owner"
                          ? "absolute top-2 right-4 cursor-pointer"
                          : "hidden"
                      } `}
                      onClick={() => {
                        setCatId(item?.id);
                        setEdit(true);
                        setName(item?.name);
                        setImage(item?.image);
                        setImageUrl(item?.image);
                        setDescription(item?.description);
                        setAddCategory(true);
                      }}
                    >
                      <img src={pencil} alt="" />
                    </div>
                    <div className="w-full h-[80px] flex items-center justify-center rounded-xl">
                      <img
                        src={
                          item?.image
                            ? item?.image
                            : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFLSvWQriAAR5hT7bf6zWuVp--jleyTS5ED_bgrnJfnk2i_qzOfMFfw4mAMMfxqcs1Sf8&usqp=CAU"
                        }
                        alt=""
                        className="h-[60px] w-[80px]"
                      />
                    </div>
                    <div className="flex items-center justify-center flex-col w-full">
                      <div className="flex items-center h-full gap-y-3 justify-center w-full flex-col">
                        <div className="text-center h-[20px] ">
                          <h1
                            className="text-gray-800 text-[15.5px] hover:text-[#1BA397] font-bold capitalize cursor-pointer"
                            // style={{ fontFamily: "poppins", fontWeight: "600" }}
                            onClick={() => {
                              if (item.route === "") {
                                Success();
                              } else {
                                if (user === "vendor-owner") {
                                  dispatch({ type: "Cat", num: item });
                                  router("/Inventory");
                                  return;
                                }
                                if (PlaceOrderCheck) {
                                  dispatch({ type: "Cat", num: item.name });
                                  router("/SuperAdmin/Inventory/SelectVendor");
                                  return;
                                }
                                dispatch({ type: "Cat", num: item.name });
                                router("/Inventory");
                              }
                            }}
                          >
                            {item?.name}
                          </h1>
                          <h1
                            className="text-gray-500 text-[15px] font-bold"
                            style={{ fontFamily: "poppins", fontWeight: "600" }}
                          >
                            {item?.productCount} Items
                          </h1>
                        </div>
                        {/* <div className="text-center min-h-[60px]">
                      <p
                        className="text-black text-[13px]"
                        style={{ fontFamily: "poppins", fontWeight: "500" }}
                      >
                        {showAllContentIndex === index ||
                        item?.content.length <= 45
                          ? item?.content
                          : `${item?.content.substring(0, 45)}...`}
                        {item?.content.length > 45 && (
                          <p
                            className="text-[#F35929] z-10"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleToggleContent(index);
                            }}
                          >
                            {showAllContentIndex === index
                              ? "Read Less"
                              : "Read More"}
                          </p>
                        )}
                      </p>
                    </div> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className={`flex justify-center mt-7`}>
                <Pagination
                  defaultCurrent={1}
                  total={totalPages * 10}
                  showSizeChanger={false}
                  onChange={pageHandler}
                  current={page}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {addCategory && (
        <AddCategory
          setShow={setAddCategory}
          setName={setName}
          name={name}
          description={description}
          setDescription={setDescription}
          image={image}
          setImage={setImage}
          setImageUrl={setImageUrl}
          imageUrl={imageUrl}
          CayegoryFunction={AddandUpdateCategory}
          edit={edit}
        />
      )}

      {showErrorModal && (
        <ErrorModal setShow={setShowErrorModal} err={err} setErr={setErr} />
      )}

      {showSuccessModal && (
        <SuccessModal setShow={setShowSuccessModal} err={err} />
      )}
    </div>
  );
};

export default AdminPanel;
