import Listing from "../components/inventory/products/listing";
import Nav from "../components/inventory/vendor/navbar";
const ListingPage = () => {
  return (
    <div className="bg-[#D9EFED] min-h-screen">
      <Nav />
      <Listing />
    </div>
  );
};

export default ListingPage;