import React, { useState, useEffect } from "react";
import { Button, Checkbox, DatePicker, Input, Select } from "antd";
import back5 from "../../../imgs/back5.png";
import { useDispatch, useSelector } from "react-redux";
import { DeleteFilled, DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import cartAni from "../../../imgs/cartAni.gif";
import chatIcon from "../../../imgs/chatIcon.svg";
import quotation from "../../../imgs/quotation.svg";
import done from "../../../imgs/done.svg";
import axios from "axios";
import dayjs from "dayjs";
import ErrorModal from "../../Merchant/Modals/errorModal";

const EventDetails = () => {
  const SelectedOrderFlow = useSelector((state) => state.SelectedOrderFlow);
  const SelectedVendors = useSelector((state) => state.SelectedVendors);
  const SelectedCompanyVendor = useSelector(
    (state) => state.SelectedCompanyVendor
  );
  const SelectedBranchVendor = useSelector(
    (state) => state.SelectedBranchVendor
  );

  const image = SelectedBranchVendor
    ? SelectedBranchVendor?.logo
    : SelectedCompanyVendor?.logo;
  const name = SelectedBranchVendor
    ? SelectedBranchVendor?.name
    : SelectedCompanyVendor?.name;

  const email = SelectedBranchVendor
    ? SelectedBranchVendor?.primaryEmail
    : SelectedCompanyVendor?.vendorUser?.email
    ? SelectedCompanyVendor?.vendorUser?.email
    : "";
  const phone = SelectedBranchVendor
    ? SelectedBranchVendor?.primaryPhone
    : SelectedCompanyVendor
    ? SelectedCompanyVendor?.vendorUser?.countryCode
      ? `${SelectedCompanyVendor?.vendorUser?.countryCode} ${
          SelectedCompanyVendor?.vendorUser?.phoneNo ?? ""
        }`
      : ""
    : "";

  const ProductTaxes = useSelector((state) => state.ProductTaxes);
  const companyId = SelectedCompanyVendor?.id;
  const selectedProgramBuyer = useSelector((state) => state.selectedProgram);
  const [err, setErr] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const { Option } = Select;
  const PlaceOrderData = useSelector((state) => state.PlaceOrderData);
  console.log("SelectedCompanyVendor", PlaceOrderData);

  const router = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cartData);
  const [localCart, setCart] = useState(cart);
  const [initial, setinitial] = useState(true);
  const [promoCode, setPromoCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const token = useSelector((state) => state.token);
  const [selectionMethod, setSelectionMethod] = useState("");
  const [warehouse, setWarehouse] = useState("");
  const [department, setDepartment] = useState("");
  const [location, setLocation] = useState("");
  const [scheduledDate, setScheduledDate] = useState(null);
  const [type, setType] = useState(null);

  const [locationData, setLocationData] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [paymentTerms, setPaymentTerms] = useState(null);
  const [selectedPaymentTerms, setSelectedPaymentTerms] = useState(null);
  const [showDone, setShowDone] = useState(false);

  const isEmpty = Object?.keys(PlaceOrderData)?.length === 0;
  useEffect(() => {
    dispatch({
      type: "PLACE_ORDER_DATA",
      num: { ...PlaceOrderData, program: selectedProgramBuyer },
    });
    if (!isEmpty) {
      setType(PlaceOrderData?.type);
      setLocation(PlaceOrderData?.mhsssfacilityId);
      setSelectedProgram(PlaceOrderData?.program);
      if (PlaceOrderData?.deliveryRequiredOn) {
        setScheduledDate(true);
        setDeliveryDate(PlaceOrderData?.deliveryRequiredOn);
      }
    }
  }, []);

  //Get Listing Payment Terms
  useEffect(() => {
    // const delay = 1000;
    const delay = 0;
    const timeoutId = setTimeout(() => {
      const params = {
        sortBy: "createdAt:desc",
        buyerProgram: selectedProgramBuyer,
        vendorcompanyId: companyId,
      };

      fetchAllAccess(params);
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const fetchAllAccess = (params) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/inv-payment-terms/list-for-buyer`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params,
        }
      )
      .then((res) => {
        let wow = [];

        res?.data?.rows.map((item, index) => {
          wow.push({
            value: item?.id,
            label: item?.method,
          });
        });
        setPaymentTerms(wow);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (type === null) {
      return;
    }

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/mhsss-facility?limit=10&sortBy=createdAt:desc&type=${type}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setLocationData(res?.data?.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [type]);

  const handleOptionChange = (e) => {
    dispatch({
      type: "PLACE_ORDER_DATA",
      num: { ...PlaceOrderData, type: e.target.value },
    });
    setType(e.target.value);
    setSelectionMethod(e.target.value);
  };

  useEffect(() => {
    setCart(cart);
  }, [cart]);
  useEffect(() => {
    if (!initial) {
      dispatch({ type: "Cart", num: localCart });
    }
  }, [localCart]);

  const CartItem = ({ item, onIncrement, onDecrement, onDelete }) => {
    return (
      <div className="flex w-full items-center rounded-xl mt-2 bg-white p-4 border-b border-gray-300 relative">
        <img
          src={item.image}
          alt={item.name}
          className="w-24 h-24 rounded-md "
        />
        <div className="ml-4 flex-grow">
          <h2 className="text-lg font-semibold">{item.name}</h2>
          <p className="text-gray-600">
            {item.price} / {item.amount}
          </p>
          <div className="flex items-center mt-2">
            <Button
              onClick={() => {
                if (item?.quantity > item?.minOrder) {
                  onDecrement(item.id);
                }
              }}
              className="bg-gray-300 text-black px-2 rounded-md"
            >
              -
            </Button>
            <span className="mx-2">{item?.quantity}</span>
            <Button
              onClick={() => onIncrement(item.id)}
              className="bg-gray-300 text-black px-2 rounded-md"
            >
              +
            </Button>
          </div>
        </div>
        <Button
          onClick={() => onDelete(item.id)}
          className="absolute top-2 right-2 text-red-600"
          icon={<DeleteFilled />}
        />
      </div>
    );
  };

  const CardItem3 = () => {
    return (
      <div className="container mx-auto p-6">
        {/* <h1 className="text-2xl font-bold text-center mb-6">Shopping Cart</h1> */}
        <div className="overflow-x-auto relative">
          <table className="min-w-full table-auto border-collapse">
            <thead>
              <tr className="border-b text-left">
                <th className="p-4">PRODUCTS</th>
                <th className="p-4">QUANTITY</th>
                <th className="p-4">DISCOUNT</th>
                <th className="p-4">TAXES</th>
                {/* <th className="p-4">REMOVE</th> */}
                <th className="p-4">DELIVERY CHARGES</th>
                <th className="p-4">TOTAL AMOUNT</th>
              </tr>
            </thead>
            <tbody>
              {localCart?.map((item) => (
                <tr key={item.id} className="border-b relative bg-white">
                  <td className="p-4 flex items-center">
                    <img
                      src={item?.image}
                      alt={item?.name}
                      className="w-20 h-20 object-cover rounded-md mr-4"
                    />
                    <div>
                      <p className="font-bold">{item?.name}</p>
                      <p className="text-sm text-gray-500">
                        {item?.type === "basePrice" ? (
                          // Show the base price if the type is 'basePrice'
                          <>
                            Price: {item?.price} / {item?.unit}
                          </>
                        ) : (
                          // If type is not 'basePrice', show a red line and fallback price
                          <>
                            <span className="line-through text-red-500">
                              Price: {item?.price} / {item?.unit}
                            </span>
                            <br />
                            Price: {item?.unitPrice} / {item?.unit}
                          </>
                        )}
                      </p>

                      {/* <p className="text-sm text-gray-500">
                        Delivery Time: {item.deliveryTime}
                      </p> */}
                      {/* <a
                        href="#"
                        className="text-blue-500 text-sm hover:underline"
                      >
                        See details
                      </a> */}
                    </div>
                  </td>
                  <td className="p-4">
                    Quantity : {item?.quantity}{" "}
                    <div className="flex items-center mt-2">
                      <Button
                        onClick={() => {
                          console.log("item", item);
                          if (item?.quantity > item?.minOrder) {
                            handleDecrement(item?.id);
                          }
                        }}
                        className="bg-gray-300 text-black px-2 rounded-md font-bold"
                      >
                        -
                      </Button>

                      <span className="mx-2">{item?.quantity}</span>
                      <Button
                        onClick={() => handleIncrement(item?.id)}
                        className="bg-gray-300 text-black px-2 rounded-md font-bold"
                      >
                        +
                      </Button>
                    </div>
                  </td>
                  <td className="p-4">${item?.discount?.toFixed(2)}</td>
                  <td className="p-4">${item?.taxes?.toFixed(2)}</td>
                  {/* <td className="p-4">
                    <button className="text-red-500 hover:underline">
                      Remove
                    </button>
                  </td> */}
                  <td className="p-4">
                    ${item?.delivery ? item?.delivery : 0}
                  </td>
                  <td className="p-4">${item?.total ? item?.total : 0}</td>

                  {/* <Button
                    onClick={() => onDelete(item.id)}
                    className="absolute top-2 right-2 text-red-600 "
                    style={{ fontSize: "18px" }}
                    icon={<DeleteFilled style={{ fontSize: "18px" }} />}
                  /> */}
                  <div className="absolute top-2 right-2 text-red-600 border border-gray-200 px-2 py-1 rounded-md cursor-pointer ">
                    <DeleteFilled
                      style={{ fontSize: "20px" }}
                      onClick={() => handleDelete(item?.id)}
                    />
                  </div>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  // Function to fetch the unit price for a single product
  const fetchUnitPrice = async (invProductId, quantity, token) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/v1/inv-order/overall-prod-items-calc-by-buyer`,
        {
          params: {
            invProductId,
            units: quantity,
            program: selectedProgramBuyer,
          },
          headers,
        }
      );

      // Extract price and other details from the response
      const price = response?.data?.invAdvancePrice?.price || 0;
      const type = response?.data?.invAdvancePrice?.type || "";
      const taxes = response?.data?.taxDetails?.totalTax || 0;
      const discounts = response?.data?.discountDetails?.totalDiscount || 0;
      const delivery = response?.data?.deliveryFee?.totalCalcDeliveryFee || 0;
      const productTaxDetails =
        response?.data?.taxDetails?.productTaxDetails || [];

      return { price, type, taxes, discounts, delivery, productTaxDetails };
    } catch (error) {
      console.error("Error fetching unit price:", invProductId, error);
      return { price: 0, type: "", productTaxDetails: [] }; // Default values
    }
  };

  // Function to fetch taxes and discounts for a single product, including unit price
  const fetchProductDetails = async (item, token) => {
    try {
      const {
        price: unitPrice,
        type,
        taxes,
        discounts,
        delivery,
        productTaxDetails,
      } = await fetchUnitPrice(item?.id, item?.quantity, token);

      // Calculate total amount for the product
      const calculateTotal = (item, priceToUse) => {
        const total =
          priceToUse * item?.quantity + taxes - discounts + delivery;
        return total > 0 ? total?.toFixed(2) : 0; // Ensure no negative totals
      };

      const total =
        item?.type === "basePrice"
          ? calculateTotal(item, unitPrice)
          : calculateTotal(item, unitPrice);

      return {
        ...item,
        unitPrice,
        taxes,
        type,
        discount: discounts,
        total,
        delivery,
        productTaxDetails,
      };
    } catch (error) {
      console.error("Error fetching product details:", item.id, error);
      return item; // Return item unchanged if there's an error
    }
  };

  // Function to update the entire cart with unit price, taxes, and discounts
  const fetchCartWithDetails = async (cartItems, token) => {
    try {
      const updatedCartItems = await Promise.all(
        cartItems?.map((item) => fetchProductDetails(item, token))
      );
      return updatedCartItems;
    } catch (error) {
      console.error("Error updating cart items with details:", error);
      return cartItems; // Return original cart if something goes wrong
    }
  };

  // React Component
  useEffect(() => {
    const updateCart = async () => {
      const updatedCart = await fetchCartWithDetails(localCart, token);
      dispatch({ type: "Cart", num: updatedCart });
      setCart(updatedCart);
      aggregateTaxes(updatedCart);
    };

    updateCart(); // Fetch data for all cart items on mount
  }, []);

  // Handle Increment
  const handleIncrement = async (id) => {
    setinitial(false);

    // Update quantity in localCart
    const updatedCart = localCart.map((item) =>
      item.id === id ? { ...item, quantity: item.quantity + 1 } : item
    );

    // Fetch updated taxes, discounts, and total price for the modified item
    const updatedItem = await fetchProductDetails(
      updatedCart.find((item) => item.id === id),
      token
    );

    // Update the cart with the new item data (with recalculated price)
    setCart(
      updatedCart.map((item) =>
        item.id === id ? { ...item, ...updatedItem } : item
      )
    );
  };

  // Handle Decrement
  const handleDecrement = async (id) => {
    setinitial(false);

    // Update quantity in localCart, ensuring it doesn't go below 1
    const updatedCart = localCart.map((item) =>
      item.id === id && item.quantity > 1
        ? { ...item, quantity: item.quantity - 1 }
        : item
    );

    // Check if the quantity was decremented (to avoid unnecessary API calls)
    const decrementedItem = updatedCart.find((item) => item.id === id);

    if (
      decrementedItem.quantity <
      localCart.find((item) => item.id === id).quantity
    ) {
      // Fetch updated taxes, discounts, and total price for the modified item
      const updatedItem = await fetchProductDetails(decrementedItem, token);

      // Update the cart with the new item data (with recalculated price)
      setCart(
        updatedCart.map((item) =>
          item.id === id ? { ...item, ...updatedItem } : item
        )
      );
    } else {
      // No API call needed if the quantity didn't change
      setCart(updatedCart);
    }
  };

  const handleDelete = (id) => {
    setinitial(false);
    setCart(localCart.filter((item) => item.id !== id));
  };

  // Calculate subtotal based on conditions
  const subtotal = localCart?.reduce((total, item) => {
    const itemPrice =
      item?.type === "basePrice" ? item?.price : item?.unitPrice || 0; // Choose price or unitPrice
    return total + itemPrice * (item?.quantity || 0); // Multiply by quantity and add to total
  }, 0);

  const totalDelivery = localCart?.reduce((total, item) => {
    return total + (item?.delivery || 0); // Add delivery charges to total, defaulting to 0 if not available
  }, 0);

  const totalDiscount = localCart?.reduce((total, item) => {
    return total + (item?.discount || 0); // Add discount to total, defaulting to 0 if not available
  }, 0);

  const totalTaxes = localCart?.reduce((total, item) => {
    return total + (item?.taxes || 0); // Add discount to total, defaulting to 0 if not available
  }, 0);

  const total = subtotal + totalDelivery + totalTaxes - totalDiscount;

  const handleApplyPromoCode = () => {
    if (promoCode === "SAP") {
      setDiscount(15);
    } else {
      setDiscount(0);
    }
  };

  const options = locationData?.map((item) => ({
    value: item?.id,
    label: item?.name,
    address: [
      item?.lot,
      item?.address,
      item?.regionNo,
      item?.regionName,
      item?.city,
      item?.ward,
    ]
      .filter(Boolean)
      .join(", "),
  }));

  const renderOption = (option) => (
    <Option key={option?.value} value={option?.value}>
      <div className="flex flex-col">
        <h1 className="font-semibold text-gray-700 text-[0.95rem]">
          {option?.label}
        </h1>
        <p className="text-sm text-gray-500 break-words whitespace-normal overflow-wrap-break-word">
          {option?.address}
        </p>
      </div>
    </Option>
  );

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/pension-programs/names-and-icons`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setPrograms(data?.data);
      })
      .catch(() => {});
  }, []);

  const programoptions = programs?.map((item) => ({
    value: item?.name,
    label: item?.name,
    imageUrl: item?.icon,
  }));

  const formatProgramNames = (data) => {
    if (data === "plwd") return "PLWD";
    else if (data === "pa-medical") return "PA Medical";
    else if (data === "pa-economical") return "PA Economical";
    else
      return data
        .replace(/-/g, " ") // Replace hyphens with spaces
        .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter after space or hyphen
  };

  const renderProgramOption = (option) => (
    <Option key={option?.value} value={option?.value}>
      <div className="flex items-center">
        <img
          src={option?.imageUrl}
          alt={option?.label}
          style={{ width: 24, marginRight: 8 }}
        />
        <span>
          {formatProgramNames(
            option?.label === "plwd"
              ? "PLWD"
              : option?.label === "pa-medical"
              ? "PA Medical"
              : option?.label === "pa-economical"
              ? "PA Economical"
              : option?.label
          )}
        </span>
      </div>
    </Option>
  );

  //Place Order

  const placeOrder = () => {
    const orderItems = localCart.map((item) => ({
      invproductId: item?.id,
      actualUnits: item?.quantity,
    }));

    const body = {
      orderItems,
      order: PlaceOrderData,
    };

    if (SelectedBranchVendor) {
      body.order.vendorbranchId = SelectedBranchVendor?.id;
    } else {
      body.order.vendorcompanyId = companyId;
    }

    delete body?.order?.type;
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/v1/inv-order`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {})
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShowErrorModal(true);
      });
  };

  //Place Order

  const sendQoutations = () => {
    const orderItems = localCart.map((item) => ({
      invproductId: item?.id,
      actualUnits: item?.quantity,
    }));

    const body = {
      orderItems,
      order: PlaceOrderData,
    };

    if (!body?.order?.mhsssfacilityId) {
      setErr("Please select delivery address");
      setShowErrorModal(true);
      return;
    }

    // if (!body?.order?.invpaymenttermId) {
    //   setErr("Please select payment term");
    //   setShowErrorModal(true);
    //   return;
    // }

    if (SelectedBranchVendor) {
      body.order.vendorbranchId = SelectedBranchVendor?.id;
    } else {
      body.order.vendorcompanyId = companyId;
    }

    delete body?.order?.type;
    delete body?.order?.invpaymenttermLabel;
    delete body?.order?.mhsssLabel;
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/inv-order/place-quotation`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setShowDone(true);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShowErrorModal(true);
      });
  };

  const aggregateTaxes = (cartItems) => {
    const taxMap = {};

    // Loop through each cart item
    cartItems?.forEach((item) => {
      item?.productTaxDetails?.forEach((tax) => {
        // Check if the tax already exists in the map
        if (taxMap[tax?.name]) {
          // Add the calculated tax to the existing amount
          taxMap[tax?.name].totalTax += parseFloat(tax?.calculatedTax || 0);
        } else {
          // Initialize the tax if it's not already in the map
          taxMap[tax?.name] = {
            totalTax: parseFloat(tax?.calculatedTax || 0),
            taxPercent: tax?.taxPercent || 0, // Default to 0 if not provided
          };
        }
      });
    });

    console.log("Aggregated Tax Map:", taxMap);

    // Convert the tax map to an array of objects
    const aggregatedTaxesData = Object?.entries(taxMap)?.map(
      ([name, { totalTax, taxPercent }]) => ({
        name,
        totalTax: totalTax?.toFixed(2), // Round to 2 decimal places
        taxPercent: taxPercent?.toFixed(2), // Round to 2 decimal places if needed
      })
    );

    dispatch({ type: "PRODUCT_TAXES", num: aggregatedTaxesData });

    console.log("Final Aggregated Taxes Array:", aggregatedTaxesData);
    return aggregatedTaxesData;
  };

  return (
    <div className="bg-[#D9EFED] h-screen">
      <div className="flex w-full pb-[5rem] items-center justify-center bg-[#D9EFED]">
        <div className="sm:w-[92%] flex flex-col items-center ">
          <div className="w-full flex items-center justify-between">
            <div className="flex justify-between w-full pl-1 pr-1 items-center mt-4">
              <div
                onClick={() => {
                  // router("/Inventory-Products");
                  router("/Inventory");
                }}
                className="cursor-pointer flex items-center justify-center"
              >
                <img className="sm:w-[1.7rem] w-[1.3rem]" src={back5} alt="" />
                <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                  Shopping Cart
                </h2>
              </div>
            </div>
          </div>

          {localCart.length > 0 && (
            <div className="p-8 mt-6 rounded-xl bg-white w-[95%] px-12">
              <div className="bg-white flex  justify-between p-4 rounded-md ">
                {/* Left side - Buyer Image and Info */}
                <div className="flex items-center space-x-4 ">
                  <img
                    src={image}
                    alt="Buyer Name"
                    className="w-20 h-20 rounded-full"
                  />
                  <div>
                    <div className="flex items-center space-x-4">
                      <h2 className="text-lg font-bold">{name}</h2>{" "}
                      <img
                        src={chatIcon}
                        alt="chat"
                        className="w-5 h-5 inline-block ml-1"
                      />
                    </div>

                    {/* <h4>
                      Branch Name{" "}
                      <span className="font-semibold text-primary">
                        Yup Branch
                      </span>
                    </h4> */}
                    <p className="text-sm text-gray-600">{phone}</p>
                    <p className="text-sm text-gray-600">{email}</p>
                  </div>
                </div>

                {/* Right side - Send Message and Role Info */}
                <div className="flex gap-6">
                  <h4 className="text-primary font-medium text-md">Location</h4>
                  <p>Region 9</p>
                </div>

                <div className="flex  flex-col gap-3">
                  <div className="">
                    <button className="text-black font-semibold text-[0.9rem] ">
                      View Quotation Histroy
                    </button>
                    <img
                      src={quotation}
                      alt="chat"
                      className="w-5 h-5 inline-block ml-1"
                    />
                  </div>
                  <button
                    onClick={() => {
                      if (SelectedCompanyVendor) {
                        const isAlreadySelected = SelectedVendors?.some(
                          (vendor) =>
                            vendor?.vendorcompany?.id ===
                            SelectedCompanyVendor?.id
                        );

                        if (!isAlreadySelected) {
                          dispatch({
                            type: "SELECTED_VENDORS",
                            num: [
                              ...SelectedVendors,
                              {
                                vendorcompany: SelectedCompanyVendor,
                                products: localCart?.map((item) => ({
                                  productId: item.id,
                                  itemCode: item?.itemCode,
                                })),
                              },
                            ],
                          });
                        }
                      }

                      if (SelectedBranchVendor) {
                        const isAlreadySelected = SelectedVendors?.some(
                          (vendor) =>
                            vendor?.vendorbranch?.id ===
                            SelectedBranchVendor?.id
                        );

                        if (!isAlreadySelected) {
                          dispatch({
                            type: "SELECTED_VENDORS",
                            num: [
                              ...SelectedVendors,
                              {
                                vendorbranch: SelectedBranchVendor,
                                products: localCart?.map((item) => ({
                                  productId: item.id,
                                  itemCode: item?.itemCode,
                                })),
                              },
                            ],
                          });
                        }
                      }

                      // dispatch({ type: "QUOTATION_FLOW", num: true });
                      router("/Inventory/SendQuotation");
                    }}
                    className="text-white font-semibold text-[0.9rem] bg-[#FF9F40] rounded-lg py-2 px-8"
                  >
                    Send Quotations
                  </button>
                </div>
              </div>
              <div className="bg-[#F6F6F6] p-5  rounded-xl">
                <CardItem3 />
                {SelectedOrderFlow?.title === "Search Product" && (
                  <div
                    className="flex items-center gap-3 bg-gray-100 p-4 rounded-md justify-center cursor-pointer"
                    onClick={() => {
                      router("/Inventory");
                    }}
                  >
                    <div className="w-7 h-7 bg-primary flex items-center justify-center rounded-full">
                      <span className="text-white text-lg font-bold">+</span>
                    </div>
                    <span className="text-black text-[0.97rem] font-medium">
                      Add More Products
                    </span>
                  </div>
                )}
                {/* {localCart.map((item) => (
                  <CardItem3
                    key={item.id}
                    item={item}
                    onIncrement={handleIncrement}
                    onDecrement={handleDecrement}
                    onDelete={handleDelete}
                  />
                ))} */}
              </div>

              <div className="w-[47%]">
                <div className="mt-4">
                  <h1 className="font-semibold text-[1rem]">
                    Select delivery Address
                  </h1>

                  <div className="my-4  w-full gap-3 rounded-md">
                    <div className="flex gap-4 mb-4 items-center justify-between text-[1rem]">
                      <div className="mb-2 flex items-center">
                        <input
                          type="radio"
                          id="warehouseOption"
                          name="selectionMethod"
                          value="warehouse"
                          className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                          checked={type === "warehouse"}
                          onChange={handleOptionChange}
                        />
                        <label
                          htmlFor="warehouseOption"
                          className="cursor-pointer text-md inline-flex items-center ml-2"
                        >
                          Warehouse
                        </label>
                      </div>
                      <div className="mb-2 flex items-center">
                        <input
                          type="radio"
                          id="departmentOption"
                          name="selectionMethod"
                          value="department"
                          className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                          checked={type === "department"}
                          onChange={handleOptionChange}
                        />
                        <label
                          htmlFor="departmentOption"
                          className="cursor-pointer text-md inline-flex items-center ml-2"
                        >
                          Department
                        </label>
                      </div>

                      <div className="mb-2 flex items-center">
                        <input
                          type="radio"
                          id="locationOption"
                          name="selectionMethod"
                          value="location"
                          className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                          checked={type === "location"}
                          onChange={handleOptionChange}
                        />
                        <label
                          htmlFor="locationOption"
                          className="cursor-pointer text-md inline-flex items-center ml-2"
                        >
                          Location
                        </label>
                      </div>
                    </div>

                    {type !== null && (
                      <Select
                        placeholder={
                          type === "warehouse"
                            ? "Select Warehouse"
                            : type === "department"
                            ? "Select Department"
                            : "Select Location"
                        }
                        className="w-[100%] woww mb-4"
                        renderOption={renderOption}
                        onChange={(value) => {
                          // Find the selected option based on the value
                          const selectedOption = options?.find(
                            (item) => item.value === value
                          );

                          // Combine the label and address into one string
                          const fullLabel = selectedOption
                            ? `${selectedOption.label} - ${selectedOption.address}`
                            : "";

                          dispatch({
                            type: "PLACE_ORDER_DATA",
                            num: {
                              ...PlaceOrderData,
                              mhsssfacilityId: value,
                              mhsssLabel: fullLabel, // Set full label including address
                            },
                          });
                          setLocation(value);
                        }}
                        value={location !== "" ? location : null}
                      >
                        {options?.map(renderOption)}
                      </Select>

                      // <Select
                      //   placeholder={
                      //     type === "warehouse"
                      //       ? "Select Warehouse"
                      //       : type === "department"
                      //       ? "Select Department"
                      //       : "Select Location"
                      //   }
                      //   // className="wow w-[100%] mb-4"
                      //   className="w-[100%] woww mb-4"
                      //   renderOption={renderOption}
                      //   onChange={(value,option) => {
                      //     dispatch({
                      //       type: "PLACE_ORDER_DATA",
                      //       num: { ...PlaceOrderData, mhsssfacilityId: value,mhsssLabel:option?.label },
                      //     });
                      //     setLocation(value);
                      //   }}
                      //   value={location !== "" ? location : null}
                      // >
                      //   {options?.map(renderOption)}
                      // </Select>
                    )}
                  </div>
                </div>

                <div className="mb-3 mt-2 ">
                  <h1 className="font-semibold text-[1rem] mb-1">
                    Select Program
                  </h1>

                  <Select
                    placeholder="Select Program"
                    className="w-[100%] woww"
                    value={selectedProgramBuyer}
                    onChange={(value) => {
                      setSelectedProgram(value);
                      dispatch({
                        type: "PLACE_ORDER_DATA",
                        num: { ...PlaceOrderData, program: value },
                      });
                    }}
                    disabled
                  >
                    {programoptions?.map(renderProgramOption)}
                  </Select>
                </div>
                {/* <div className="mb-3 mt-2 ">
                  <h1 className="font-semibold text-[1rem] mb-1">
                    Select Payment Terms
                  </h1>

                  <Select
                    placeholder="Select Payment Term"
                    className="w-[100%] woww"
                    value={selectedPaymentTerms}
                    onChange={(value, option) => {
                      setSelectedPaymentTerms(value);
                      dispatch({
                        type: "PLACE_ORDER_DATA",
                        num: {
                          ...PlaceOrderData,
                          invpaymenttermId: value,
                          invpaymenttermLabel: option.label, // Fix: Extract label from option
                        },
                      });
                    }}
                    options={paymentTerms}
                  />
                </div> */}
              </div>
              <div className="pt-6">
                <Checkbox
                  checked={scheduledDate}
                  onChange={(e) => setScheduledDate(e.target.checked)}
                  className={"checkk4 "}
                >
                  Specify if you need scheduled delivery or same-day delivery
                  for this order.
                </Checkbox>
                <div className={`${scheduledDate ? "w-[45%] mt-3" : "hidden"}`}>
                  <DatePicker
                    style={{ marginBottom: "1.2rem" }}
                    className={`${"date-class1 p-5"} `}
                    placeholder="mm/dd/yyyy"
                    onChange={(e, f) => {
                      setDeliveryDate(f);
                      dispatch({
                        type: "PLACE_ORDER_DATA",
                        num: { ...PlaceOrderData, deliveryRequiredOn: f },
                      });
                    }}
                    value={deliveryDate !== null ? dayjs(deliveryDate) : null}
                    disabledDate={(current) =>
                      current && current < dayjs().startOf("day")
                    }
                    allowClear={false}
                  />
                </div>
              </div>
              <div className="mt-6 p-4 w-full bg-[#F6F6F6] rounded-xl text-[0.92rem]">
                <div className="flex w-full justify-between mb-2">
                  <span className="font-semibold">Total Items</span>
                  <span className="font-semibold">{localCart?.length}</span>
                </div>
                <div className="flex w-full justify-between mb-2">
                  <span className="font-semibold">Sub Total </span>
                  <span className="font-semibold">${subtotal?.toFixed(2)}</span>
                </div>

                <div className="flex justify-between mb-2">
                  <span className="font-semibold">Total Delivery Charges</span>
                  <span className="font-semibold">
                    ${totalDelivery?.toFixed(2)}
                  </span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="font-semibold">Discount</span>
                  <span className="font-semibold">
                    ${totalDiscount?.toFixed(2)}
                  </span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="font-semibold">Taxes</span>
                  <span className="font-semibold">
                    ${totalTaxes?.toFixed(2)}
                  </span>
                </div>
                <div
                  className={`${
                    ProductTaxes.length > 0
                      ? "bg-[#EDEDED] px-6 py-2 text-gray-600"
                      : "hidden"
                  }`}
                >
                  {ProductTaxes &&
                    ProductTaxes?.slice()
                      ?.reverse()
                      ?.map((item, index) => (
                        <div key={index} className="flex justify-between mb-2">
                          <span className="font-semibold">
                            {item?.name} (
                            {item?.taxPercent
                              ? Math.round(item?.taxPercent)
                              : 0}
                            %)
                          </span>
                          <span className="font-semibold">
                            ${item?.totalTax}
                          </span>
                        </div>
                      ))}
                </div>
                <div className="flex justify-between font-bold text-lg mt-3">
                  <span>Total</span>
                  <span>${total?.toFixed(2)}</span>
                </div>
              </div>
            </div>
          )}
          {/* {localCart.length > 0 && (
            <div className="flex gap-4 w-full items-center mt-6">
              <Input
                className="bg-gray-50 border border-gray-300 pl-4 pr-4 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 w-full p-2"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                placeholder="Enter promo code"
              />
              <Button
                onClick={handleApplyPromoCode}
                className="bg-primary rounded-xl cursor-pointer pl-8 pr-8 h-[2.5rem] text-semibold text-white"
              >
                Apply
              </Button>
            </div>
          )} */}

          {localCart.length > 0 && (
            <div className="mt-8 flex gap-5">
              <Button
                // onClick={() => router("/Inventory-PlaceOrder")}
                className="bg-red-600 rounded-xl cursor-pointer pl-8 pr-8 h-[2.5rem] text-semibold text-white w-[12rem]"
              >
                Discard Cart
              </Button>
              <Button
                // onClick={() => router("/Inventory-PlaceOrder")}
                onClick={() => {
                  if (!PlaceOrderData?.mhsssfacilityId) {
                    setErr("Please select delivery address");
                    setShowErrorModal(true);
                    return;
                  }

                  // if (!PlaceOrderData?.invpaymenttermId) {
                  //   setErr("Please select payment term");
                  //   setShowErrorModal(true);
                  //   return;
                  // }
                  router("/PurchaseOrder");
                }}
                // onClick={() => {
                //   placeOrder();
                // }}
                className="bg-primary rounded-xl cursor-pointer pl-8 pr-8 h-[2.5rem] text-semibold text-white w-[12rem]"
              >
                Create Purchase Order
                {/* Place Order */}
              </Button>
            </div>
          )}

          {localCart.length === 0 && (
            <div className="mt-8">
              <img src={cartAni} className="w-[30rem] h-64 rounded-md" />
              <h1 className="text-2xl font-bold text-center mt-4 text-gray-600">
                Cart Is Empty ....
              </h1>
            </div>
          )}
        </div>
      </div>
      {showErrorModal && <ErrorModal setShow={setShowErrorModal} err={err} />}
      <div
        className={`${
          showDone ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="rounded-xl w-[22rem] pb-6 flex items-center justify-center p-3 bg-white flex-col">
          <img
            onClick={() => setShowDone(false)}
            src={done}
            alt=""
            className="w-20 h-20"
          />
          <p className="font-bold text-lg mt-[1.5rem] text-primary capitalize">
            Quotation request sent
          </p>
          <p className="text-center mt-5 mb-5">
            Your quotation request has been successfully submitted.
          </p>
          <button
            onClick={() => {
              setShowDone(false);
              router("/SuperAdmin/InventoryDashboard");
            }}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
