import back5 from "../../../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import Nav from "../../../vendor/navbar";
import Filters from "../filters/newFilter";

import Table from "../table";
import VendorCards from "./main";
import CompanyBranch from "./selectCompanyBranch";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
const Listing = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const user = useSelector((state) => state.user);
  const [search, setSearch] = useState("");
  const QuotationFlow = useSelector((state) => state.QuotationFlow);

  useEffect(() => {
    if (QuotationFlow) {
      return;
    }

    dispatch({
      type: "PLACE_ORDER_DATA",
      num: {},
    });
    dispatch({ type: "PRODUCT_TAXES", num: [] });
    dispatch({ type: "Cart", num: [] });
    dispatch({ type: "SELECTED_COMPANY_VENDOR", num: null });
    dispatch({ type: "SELECTED_BRANCH_VENDOR", num: null });
    // dispatch({ type: "QUOTATION_FLOW", num: false });
  }, []);

  return (
    <div className="bg-[#D9EFED] min-h-screen h-screen">
      <Nav />
      <div className="flex items-center justify-center bg-[#D9EFED] ">
        <div className="sm:w-[95%] w-full  sm:px-10 px-6 rounded-md mb-12">
          <div className="cursor-pointer flex w-full items-center justify-center mt-3">
            <img
              onClick={() => {
                if (QuotationFlow) {
                  router("/Inventory/SendQuotation");
                  return;
                }
                if (user === "vendor-owner") {
                  router("/VendorDashboard");
                  return;
                }
                // router("/Inventory/SelectProgram");
                router("/Inventory/SelectOption")
              }}
              className="sm:w-[1.7rem] w-[1.3rem]"
              src={back5}
              alt=""
            />
            <h2 className="font-bold w-full sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
              {user === "vendor-owner"
                ? "Select company/ branch"
                : "Select Vendor"}
            </h2>
          </div>
          {/* <Filters search={search} setSearch={setSearch} /> */}
          <div className="rounded-md sm:p-2">
            {user === "vendor-owner" ? (
              <CompanyBranch search={search} setSearch={setSearch} />
            ) : (
              <VendorCards search={search} setSearch={setSearch} />
            )}

            {/* <Table search={search} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listing;
