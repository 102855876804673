import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../../../Merchant/Modals/errorModal";
import axios from "axios";

const SelectProgram = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [err, setErr] = useState("");
  const [showErr, setShowErr] = useState(false);
  const [programs, setPrograms] = useState([]);
  const SelectedOrderFlow = useSelector((state) => state.SelectedOrderFlow);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/pension-programs/names-and-icons`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setPrograms(data?.data);
      })
      .catch(() => {});
  }, []);

  const formatProgramNames = (data) => {
    if (data === "plwd") return "PLWD";
    else if (data === "pa-medical") return "PA Medical";
    else if (data === "pa-economical") return "PA Economical";
    else
      return data
        .replace(/-/g, " ") // Replace hyphens with spaces
        .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter after space or hyphen
  };

  return (
    <div className="">
      <div className="flex justify-center">
        <div
          className={`bg-[#fafaf9] shadow-lg rounded-md mx-4 m-5 p-8 sm:w-[95%] w-full`}
        >
          <div
            className={`grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-5 `}
          >
            {programs?.map((program, index) => (
              <div
                key={index}
                className={`flex flex-row items-center justify-start p-2  shadow-md rounded-md mb-3 border cursor-pointer ${
                  selectedProgram === program.name
                    ? "bg-primary text-white"
                    : "text-gray-600"
                }`}
                onClick={() => {
                  if (selectedProgram === program.name) {
                    setSelectedProgram(null);
                    return;
                  }
                  setSelectedProgram(program.name);
                  dispatch({ type: "SELECTED_PROGRAM", num: program.name });
                }}
              >
                <div
                  className={`flex items-center justify-center bg-white p-2 rounded-full`}
                >
                  <img src={program?.icon} alt="alt" className="w-5 h-5 " />
                </div>

                <h1 className={`font-semibold text-[0.9rem]  ml-2 capitalize`}>
                  {formatProgramNames(program?.name)}
                </h1>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className={`mt-3 flex  items-center sm:justify-center justify-center w-full mb-5 `}
      >
        <button
          type="button"
          onClick={() => {
            if (selectedProgram === null) {
              setErr("Please select a program");
              setShowErr(true);
              return;
            }

            SelectedOrderFlow?.title === "Search Product" ? router("/Inventory/SelectProduct"):router("/Inventory/SelectOption");
            // router("/pensionerVerification/selectedProgram");
            // router("/SuperAdmin/Inventory/SelectVendor");
            
          }}
          className={`lg:text-[1rem] bg-gradient-to-tr  bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[12rem]`}
        >
          Next
        </button>
      </div>
      {showErr && <ErrorModal show={showErr} setShow={setShowErr} err={err} />}
    </div>
  );
};

export default SelectProgram;
