import React, { useState, useEffect } from "react";
import { Button, Carousel, Pagination } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import like from "../../../../imgs/heartF.png";
import dislike from "../../../../imgs/heartD.png";
import cartW from "../../../../imgs/cartW.png";

import Footer from "../../cart/footer";
import axios from "axios";
import BranchModal from "./branchModal";
import Filter from "../../superAdmin/vendor/filters/newFilter";
const EventCard = () => {
  const [debouncedQuantity, setDebouncedQuantity] = useState(""); // Debounced value
  const SelectedCompanyVendor = useSelector(
    (state) => state.SelectedCompanyVendor
  );
  const SelectedBranchVendor = useSelector(
    (state) => state.SelectedBranchVendor
  );

  const companyId = SelectedCompanyVendor?.id;
  const router = useNavigate();
  const dispatch = useDispatch();
  const category = useSelector((state) => state.category);
  const cart = useSelector((state) => state.cartData);
  const role = useSelector((state) => state.role);
  const fav = useSelector((state) => state.fav);
  const [localCart, setCart] = useState(cart);
  const [favorite, setFavorite] = useState(fav);
  const [initial, setinitial] = useState(true);
  const [initialFav, setinitialFav] = useState(true);
  const user = useSelector((state) => state.user);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const token = useSelector((state) => state.token);
  const [data, setData] = useState([]);
  const [showBranch, setShowBranch] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [buyerBranch, setBuyerBranch] = useState(null);
  const [afterapi, setAfterApi] = useState(false);
  const [categoriesFilter, setCategoriesFilter] = useState([]);
  console.log("cart", cart);

  useEffect(() => {
    if (SelectedBranchVendor !== null) {
      setBuyerBranch(SelectedBranchVendor?.id);
    }
  }, []);

  useEffect(() => {
    dispatch({ type: "EDIT_PRODUCT", num: false });
    dispatch({ type: "PRODUCT_ID", num: null });
    setCart(cart);
  }, [cart]);
  useEffect(() => {
    setFavorite(fav);
  }, [fav]);
  useEffect(() => {
    if (!initial) {
      dispatch({ type: "Cart", num: localCart });
    }
  }, [localCart]);
  useEffect(() => {
    // console.log("fav", favorite);
    if (!initialFav) {
      dispatch({ type: "Fav", num: favorite });
    }
  }, [favorite]);

  const addToCart = (data) => {
    setinitial(false);
    const cartItem = {
      id: data?.id,
      image: data?.invproductgallaries?.find((gallery) => gallery?.isCoverImg)
        ?.image
        ? data?.invproductgallaries?.find((gallery) => gallery?.isCoverImg)
            ?.image
        : "https://t3.ftcdn.net/jpg/03/45/05/92/360_F_345059232_CPieT8RIWOUk4JqBkkWkIETYAkmz2b75.jpg",
      name: data?.name,
      quantity: data?.addedQuantity !== null ? data?.addedQuantity : 1,
      price: parseFloat(data?.basePrice), // Convert to number
      unit: data?.pricingUnit,
      minOrder: data?.minimumOrderQty,
      itemCode: data?.itemCode,
    };

    const updatedCart = [...localCart, cartItem];
    setCart(updatedCart);
  };
  const addToFav = (event) => {
    setinitialFav(false);
    const favItems = {
      id: event.id,
      coverPhotos: event.invproductgallaries,
      name: event.name,
      route: event.route,
      quantity: event.quantity,
      price: event.basePrice,
      amount: event.amount,
      minOrder: event?.minimumOrderQty,
    };

    const updatedFav = [...favorite, favItems];
    setFavorite(updatedFav);
  };

  const changeQuantity = (event) => {
    setinitial(false);
    const updatedCart = localCart.map((item) => {
      if (item.id === event.id) {
        return {
          ...item,
          quantity: event.quantity,
        };
      }
      return item;
    });
    setCart(updatedCart);
  };

  const removeFromCart = (eventId) => {
    setinitial(false);
    const updatedCart = localCart.filter((item) => item.id !== eventId);
    setCart(updatedCart);
  };
  const removeFromFav = (eventId) => {
    setinitialFav(false);
    const updatedFav = favorite.filter((item) => item.id !== eventId);
    setFavorite(updatedFav);
  };

  const isAddedToCart = (eventId) => {
    return localCart.some((item) => item.id === eventId);
  };
  const isAddedToFav = (eventId) => {
    return favorite.some((item) => item.id === eventId);
  };

  const increaseQuantity = (res) => {
    setinitial(false);
    if (isAddedToCart(res?.id)) {
      const updatedCart = localCart.map((item) => {
        if (item.id === res?.id) {
          // Increment the quantity of the existing item
          return { ...item, quantity: item.quantity + 1 };
        }
        return item;
      });

      setData((prevData) =>
        prevData.map((product) =>
          product.id === res?.id
            ? {
                ...product,
                addedQuantity: product?.addedQuantity + 1,
              }
            : product
        )
      );

      setCart(updatedCart);
    } else {
      setData((prevData) =>
        prevData.map((product) =>
          product.id === res?.id
            ? {
                ...product,
                addedQuantity: product?.addedQuantity + 1,
              }
            : product
        )
      );
    }
  };

  const decreaseQuantity = (res) => {
    setinitial(false);
    if (isAddedToCart(res?.id)) {
      const updatedCart = localCart
        .map((item) => {
          if (item.id === res?.id) {
            if (item.quantity > 1) {
              // Decrease the quantity if it's greater than 1
              return { ...item, quantity: item.quantity - 1 };
            }
            // Call the removeCart function for quantity 1
            removeFromCart(item.id);
            return null; // Mark for removal
          }
          return item;
        })
        .filter(Boolean); // Remove null items
      setData((prevData) =>
        prevData.map((product) =>
          product?.id === res?.id
            ? {
                ...product,
                addedQuantity: product?.addedQuantity - 1,
              }
            : product
        )
      );
      setCart(updatedCart);
    } else {
      setData((prevData) =>
        prevData.map((product) =>
          product?.id === res?.id
            ? {
                ...product,
                addedQuantity: product?.addedQuantity - 1,
              }
            : product
        )
      );
    }
  };

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");

  //Get Listing
  useEffect(() => {
    // if (user === "superadmin") {
    //   setData(events);
    //   return;
    // }
    setAfterApi(false);
    const delay = 1000;
    const timeoutId = setTimeout(() => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const vendorParams = {
        limit: 12,
        skip: 0,
        page: page,
        sortBy: "createdAt:desc",
        ...(search && { search }),
        ...(selectedBranch && { vendorbranchId: selectedBranch?.id }),
        ...(category && { invcategoryId: category?.id }),
      };

      const buyerParams = {
        limit: 12,
        skip: 0,
        page: page,
        sortBy: "createdAt:desc",
        ...(search && { search }),
        ...(SelectedBranchVendor
          ? { vendorbranchId: SelectedBranchVendor?.id }
          : { vendorcompanyId: companyId }),
        ...(categoriesFilter?.length > 0 && {
          invcategoryIDs: categoriesFilter,
        }),
        // ...(category && { invcategoryId: category?.id }),
      };

      const params =
        user === "superadmin" || user === "employee"
          ? buyerParams
          : vendorParams;

      fetchAllAccess(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [page, selectedBranch, search, categoriesFilter]);

  const fetchAllAccess = (params, cancelTokenSource) => {
    const api =
      user === "superadmin" || user === "employee"
        ? `v1/inv-product/buyer-list-products`
        : "v1/inv-product/vendor-query-own-products";
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        const modifiedData = res?.data?.rows?.map((item) => ({
          ...item,
          addedQuantity:
            item?.minimumOrderQty !== null ? item?.minimumOrderQty : 1, // Add the new property
        }));
        setData(modifiedData);
        setTotalPages(res?.data?.totalPages);
        setAfterApi(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // console.log(data);

  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <Button
        className="absolute border-none hover:bg-[#1BA397] z-10 h-7 w-7 top-1/2 flex items-center justify-center rounded-full  left-0 transform -translate-y-1/2"
        onClick={onClick}
      >
        <LeftOutlined className="text-white font-semibold" />
      </Button>
    );
  };

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <Button
        className="absolute border-none hover:bg-[#1BA397] z-10 h-7 w-7 top-1/2 flex items-center justify-center rounded-full  right-0 transform -translate-y-1/2"
        onClick={onClick}
      >
        <RightOutlined className="text-white font-semibold" />
      </Button>
    );
  };

  const pageHandler = (e) => {
    setPage(e);
  };

  return (
    <div className="flex w-full  pb-[8rem] flex-col justify-center mt-4  ">
      {user === "superadmin" || user === "employee" ? (
        <div className="w-full">
          <Filter
            search={search}
            setSearch={setSearch}
            setCategoriesFilter={setCategoriesFilter}
            categoriesFilter={categoriesFilter}
          />
        </div>
      ) : null}
      <div
        className={`${
          user === "superadmin" || user === "employee"
            ? "hidden"
            : "flex justify-end mt-4 gap-3 mb-4"
        }`}
      >
        <div
          onClick={() => {
            setShowBranch(true);
          }}
          className="bg-white w-[45%] border rounded-lg md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0"
        >
          <div className="w-full  md:w-auto md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
            <div className="flex items-center space-x-3 w-full md:w-auto">
              <div className="relative w-full">
                <button
                  id="filterDropdownButton"
                  data-dropdown-toggle="filterDropdown"
                  className="mr-3 w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900  focus:z-10     "
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    className="h-4 w-4 mr-2 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Filter By
                  <svg
                    className="-mr-1 ml-1.5 w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      clipRule="evenodd"
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center bg-[#f9fbfb] border border-gray-300 rounded-md px-4 py-3 w-[300px] shadow-sm">
          <input
            type="text"
            placeholder="Search Product"
            className="bg-transparent focus:outline-none text-gray-500 placeholder-gray-400 flex-grow"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            value={search}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-[#00b0a6]"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M11 19a8 8 0 100-16 8 8 0 000 16zm5-1l5 5"
            />
          </svg>
        </div>

        {user === "vendor-owner" && (
          <button
            type="button"
            onClick={() => {
              router("/Inventory-AddProduct");
            }}
            className="cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[12rem]"
          >
            Add Product
          </button>
        )}
      </div>
      <div
        className={`${
          selectedBranch === null
            ? "hidden"
            : "bg-white w-full p-3 rounded-lg shadow-md mb-4 flex items-center justify-between px-4"
        }`}
      >
        <h1 className="font-semibold text-primary">{selectedBranch?.name} </h1>
        <h1
          className="font-semibold text-gray-800 cursor-pointer"
          onClick={() => {
            setSelectedBranch(null);
          }}
        >
          Clear Filter
        </h1>
      </div>

      {/* w-full flex flex-wrap md:gap-5 gap-3 gap-y-8 mt-2 justify-center */}
      {data?.length <= 0 && afterapi ? (
        <div className="w-full flex items-center justify-center  bg-white  mt-10 rounded-md">
          <h1 className="text-lg font-semibold text-gray-600 p-32">
            No Products Found
          </h1>
        </div>
      ) : (
        <div className="bg-white p-6">
          <div className="w-full grid grid-cols-1 gap-7 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-2 xl:grid-cols-4">
            {data?.map((event) => (
              <>
                <div class="w-full flex  justify-center   ">
                  <article class="max-w-sm w-full bg-[#F8F6F6] rounded-lg shadow-lg overflow-hidden">
                    <div className="bg-red">
                      <img
                        class="object-fill h-64 w-full p-4 bg-gray-50"
                        src={
                          event?.invproductgallaries?.find(
                            (gallery) => gallery?.isCoverImg
                          )?.image
                            ? event?.invproductgallaries?.find(
                                (gallery) => gallery?.isCoverImg
                              )?.image
                            : "https://t3.ftcdn.net/jpg/03/45/05/92/360_F_345059232_CPieT8RIWOUk4JqBkkWkIETYAkmz2b75.jpg"
                        }
                        alt="Converse sneakers"
                      />
                    </div>

                    <div class="flex flex-col gap-1 mt-1 px-4">
                      <div className="flex items-center justify-between">
                        <h2
                          onClick={() => {
                            if (user === "vendor-owner") {
                              dispatch({
                                type: "Product",
                                num: {
                                  ...event,
                                  addedQuantity: event?.minimumOrderQty,
                                },
                              });

                              // dispatch({ type: "Product", num: event });
                              dispatch({ type: "EDIT_PRODUCT", num: true });
                              dispatch({ type: "PRODUCT_ID", num: event?.id });
                              router("/Inventory/ProductDetail");
                              return;
                            }
                            dispatch({ type: "Product", num: event });
                            router("/Inventory/Product/Detail");
                            // router(role === "buyer" ? event?.route : event.routeV);
                          }}
                          class="text-lg font-semibold text-gray-800 hover:text-[#1BA397] cursor-pointer capitalize"
                        >
                          {event.name}
                        </h2>

                        <span class="font-semibold text-gray-800 ">
                          ${event?.basePrice}/{event?.pricingUnit}
                        </span>
                      </div>

                      {user !== "vendor-owner" && (
                        <div className="flex items-center justify-between mb-3">
                          {/* Minimum Order Quantity */}
                          <div className="flex items-center">
                            <span className="text-gray-500 font-semibold mr-1 text-[13px]">
                              Min Order Quantity:
                            </span>
                            <span className="text-red-500 font-semibold">
                              {event?.minimumOrderQty !== null
                                ? event?.minimumOrderQty
                                : 1}
                            </span>
                          </div>

                          {/* Add to Cart Button */}
                          <div>
                            {isAddedToCart(event?.id) ? (
                              <button
                                className="text-white bg-red-600 px-2 py-1 rounded-md font-semibold text-[14px]"
                                onClick={() => {
                                  removeFromCart(event?.id);
                                }}
                              >
                                Remove
                              </button>
                            ) : (
                              <button
                                className="text-white bg-primary px-2 py-1 rounded-md font-semibold text-[14px]"
                                onClick={() => {
                                  addToCart(event);
                                }}
                              >
                                Add To Cart
                              </button>
                            )}
                          </div>
                        </div>
                      )}

                      {/* For Vendor */}

                      {user === "vendor-owner" && (
                        <p className="text-red-500 w-full  font-semibold mb-2 ">
                          {event?.stock}{" "}
                          <span className="text-gray-600 w-full  font-semibold">
                            {" "}
                            Items Left
                          </span>
                        </p>
                      )}
                    </div>

                    {user !== "vendor-owner" && isAddedToCart(event?.id) && (
                      <div className="w-full  px-3 pb-3  ">
                        <div className="p-3 bg-white border-gray-200 rounded-b-lg ">
                          <div className="flex items-center justify-between">
                            <h4>Quantity</h4>
                            <button className="rounded-md cursor-pointer pl-5 pr-5 text-semibold text-white p-1">
                              <div className="flex items-center justify-center gap-2">
                                <div
                                  onClick={() => {
                                    if (isAddedToCart(event?.id)) {
                                      if (
                                        localCart.find(
                                          (item) => item.id === event?.id
                                        )?.quantity <= event?.minimumOrderQty
                                      ) {
                                        return;
                                      }
                                      decreaseQuantity(event);
                                    } else {
                                      if (
                                        event?.addedQuantity <=
                                        event?.minimumOrderQty
                                      ) {
                                        return;
                                      }
                                      setData((prevData) =>
                                        prevData.map((product) =>
                                          product.id === event?.id
                                            ? {
                                                ...product,
                                                addedQuantity:
                                                  product?.addedQuantity - 1,
                                              }
                                            : product
                                        )
                                      );
                                    }
                                  }}
                                  className="bg-white border border-primary rounded-md cursor-pointer pl-2 pr-2 text-bold text-gray-800 p-1"
                                >
                                  -
                                </div>
                                {/* <input
                                  className="bg-primary rounded-md cursor-pointer pl-3 pr-3 text-semibold text-white p-1 w-14 text-center"
                                  value={
                                    localCart.find(
                                      (item) => item.id === event?.id
                                    )?.quantity ||
                                    event?.minimumOrderQty !== null
                                      ? event?.minimumOrderQty
                                      : 1
                                  }
                                /> */}

                                <input
                                  className="bg-primary rounded-md cursor-pointer pl-3 pr-3 text-semibold text-white p-1 w-14 text-center"
                                  // value={
                                  //   localCart.find(
                                  //     (item) => item.id === event?.id
                                  //   )?.quantity || event?.addedQuantity !== null
                                  //     ? event?.addedQuantity
                                  //     : 1
                                  // }
                                  value={
                                    isAddedToCart(event?.id)
                                      ? localCart.find(
                                          (item) => item.id === event?.id
                                        )?.quantity
                                      :
                                    event?.addedQuantity !== null
                                      ? event?.addedQuantity
                                      : 1
                                  }
                                  onChange={(e) => {
                                    // Parse the input value
                                    let inputQuantity = parseInt(
                                      e.target.value
                                    );

                                    // If the input is invalid (NaN), reset input to an empty string (optional)
                                    if (isNaN(inputQuantity)) {
                                      inputQuantity = "";
                                    }

                                    

                                    if (isAddedToCart(event?.id)) {
                                      setCart((prevCart) =>
                                        prevCart.map((item) =>
                                          item.id === event?.id
                                            ? {
                                                ...item,
                                                quantity: inputQuantity,
                                              }
                                            : item
                                        )
                                      );
                                    }

                                    // Update the state with the temporary input value
                                    setData((prevData) =>
                                      prevData.map((product) =>
                                        product.id === event?.id
                                          ? {
                                              ...product,
                                              addedQuantity: inputQuantity,
                                            }
                                          : product
                                      )
                                    );
                                  }}
                                  onBlur={() => {
                                    // Get the current input quantity
                                    // const currentQuantity = localCart.find(
                                    //   (item) => item.id === event?.id
                                    // )?.addedQuantity;

                                    const currentQuantity =
                                      event?.addedQuantity;

                                    // Check if the current quantity is less than the minimum order quantity
                                    const minimumQuantity =
                                      event?.minimumOrderQty || 1;

                                    if (currentQuantity < minimumQuantity) {
                                      // If the current quantity is less than the minimum, reset to minimum quantity
                                      setData((prevData) =>
                                        prevData.map((product) =>
                                          product.id === event?.id
                                            ? {
                                                ...product,
                                                addedQuantity: minimumQuantity,
                                              }
                                            : product
                                        )
                                      );
                                    }
                                  }}
                                />

                                <div
                                  onClick={() => {
                                    if (isAddedToCart(event?.id)) {
                                      increaseQuantity(event);
                                    } else {
                                      setData((prevData) =>
                                        prevData.map((product) =>
                                          product.id === event?.id
                                            ? {
                                                ...product,
                                                addedQuantity:
                                                  product?.addedQuantity + 1,
                                              }
                                            : product
                                        )
                                      );
                                    }
                                  }}
                                  className="bg-white border border-primary rounded-md cursor-pointer pl-2 pr-2 text-bold text-gray-800 p-1"
                                >
                                  +
                                </div>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </article>
                </div>
              </>
            ))}
          </div>
          <div className={`flex justify-center mt-7`}>
            <Pagination
              defaultCurrent={1}
              total={totalPages * 10}
              showSizeChanger={false}
              onChange={pageHandler}
              current={page}
            />
          </div>
        </div>
      )}

      {cart?.length > 0 && user !== "vendor-owner" && <Footer />}
      {showBranch && (
        <BranchModal
          setShow={setShowBranch}
          setSelectedBranch={setSelectedBranch}
        />
      )}
    </div>
  );
};

export default EventCard;
