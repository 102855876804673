import { useSelector } from "react-redux";
import { PlusCircleFilled, DeleteFilled } from "@ant-design/icons";
import axios from "axios";
import { Rate, Upload } from "antd";
import { useState } from "react";
let file;
const PaymentMethodModal = ({
  setShow,
  setErrorModal,
  setErr,
  setShowSuccessModal,
  setRoute,
  data,
}) => {
  const OrderData = useSelector((state) => state.OrderData);
  console.log(OrderData);

  const token = useSelector((state) => state.token);
  const [stars, setStars] = useState(0); // Default: 0 stars
  const [feedbackNote, setFeedbackNote] = useState(""); // Required field
  const [receiptImg, setReceiptImg] = useState(null); // Optional image
  const [productImg, setProductImg] = useState(null); // Optional image

  const imageHandler = (setState, type) => async (e) => {
    file = e.target.files[0];
    // const base641 = await convertToBase64(file);
    // setState(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=${type}`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        setState(data.data.url);
        e.target.value = null;
      })
      .catch(() => {});
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const submitFeedback = () => {
    const api = `v1/vendor-rating`;
    if (feedbackNote === "") {
      setErr("Please enter feedback");
      setErrorModal(true);
      return;
    }

    // Construct payload dynamically to exclude undefined/null fields
    const payload = {
      stars,
      feedbackNote,
      invorderId: OrderData?.id,
    };

    if (receiptImg) payload.receiptImg = receiptImg;
    if (productImg) payload.productImg = productImg;

    // Ensure at least one of vendorcompanyId or vendorbranchId is included
    if (data?.vendorCompany) {
      payload.vendorcompanyId = data?.vendorCompany?.id;
    } else if (data?.vendorBranch) {
      payload.vendorbranchId = data?.vendorBranch?.id;
    }

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/${api}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setErr("Feedback submitted successfully");
        setRoute("/Inventory-Vendor-Orders");
        setShowSuccessModal(true);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message || "An error occurred");
        setErrorModal(true);
      });
  };

  return (
    <div
      className={`w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center `}
    >
      <div className="relative p-4 w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow ">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
            <h3 className="text-lg font-semibold text-gray-900 ">
              Your Feedback
            </h3>
            <button
              onClick={() => setShow(false)}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center  "
              data-modal-toggle="select-modal"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <div className="p-4 md:p-5 min-h-[150px] md:min-h-[200px] max-h-[75vh] overflow-auto">
            {/* Star Rating Input using Ant Design */}
            <div className="flex items-center justify-between">
              <label className="block text-[0.95rem]">Rating</label>
              <Rate
                value={stars}
                onChange={setStars}
                style={{ fontSize: 24 }}
              />
            </div>

            <textarea
              value={feedbackNote}
              onChange={(e) => setFeedbackNote(e.target.value)}
              className="border p-2 rounded w-full bg-gray-100 mt-2"
              required
              placeholder="Type feedback here"
              rows={4}
            />

            <div className="mt-2">
              <label
                //   htmlFor="special-input"
                className={`cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[0.95rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center justify-center">
                  <div>
                    <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">
                    Upload Receipt (optional)
                  </p>
                </div>
                <input
                  type="file"
                  className="hidden w-[100%] cursor-poniter"
                  // id="special-input"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(setReceiptImg, "courierReceipt")}
                />
              </label>
              <div
                className={`relative mb-4 flex items-center justify-center bg-[#F8F8F8]
                          ${receiptImg !== null ? "" : "hidden"}`}
              >
                <img
                  onClick={() => {
                    // setIShow(true);
                  }}
                  src={receiptImg}
                  className="w-[13rem] h-[6rem]"
                  //   src={GFront}
                  alt=""
                />
                <div
                  className="absolute bottom-0 right-0 bg-white cursor-pointer"
                  onClick={() => {
                    setReceiptImg(null);
                  }}
                >
                  <DeleteFilled
                    className="sm:w-[3rem] w-[2rem]"
                    style={{ fontSize: "30px", color: "#1BA397" }}
                    onClick={() => {
                      setReceiptImg(null);
                    }}
                  />
                </div>
              </div>
            </div>

            <div>
              <label
                //   htmlFor="special-input"
                className={`cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[0.95rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center justify-center">
                  <div>
                    <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">
                    Upload Product image (optional)
                  </p>
                </div>
                <input
                  type="file"
                  className="hidden w-[100%] cursor-poniter"
                  // id="special-input"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(setProductImg, "productImage")}
                />
              </label>
              <div
                className={`relative mb-4 flex items-center justify-center bg-[#F8F8F8]
                          ${productImg !== null ? "" : "hidden"}`}
              >
                <img
                  onClick={() => {
                    // setIShow(true);
                  }}
                  src={[productImg]}
                  className="w-[13rem] h-[6rem]"
                  //   src={GFront}
                  alt=""
                />
                <div
                  className="absolute bottom-0 right-0 bg-white cursor-pointer"
                  onClick={() => {
                    setProductImg(null);
                  }}
                >
                  <DeleteFilled
                    className="sm:w-[3rem] w-[2rem]"
                    style={{ fontSize: "30px", color: "#1BA397" }}
                    onClick={() => {
                      setProductImg(null);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="flex gap-2 p-4">
              <button
                onClick={() => {
                  setShow(false);
                }}
                className="text-primary inline-flex pr-2 pl-2 w-full justify-center bg-white border border-primary  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                View Order Detail
              </button>
              <button
                onClick={() => submitFeedback()}
                className="text-white inline-flex pr-2 pl-2 w-full justify-center bg-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Submit Feedback
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* {showErrorModal && <ErrorModal setShow={setShowErrorModal} err={err} />} */}
    </div>
  );
};

export default PaymentMethodModal;
