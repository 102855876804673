import back5 from "../../../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";

import Cards from "./Cards";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import { useEffect, useState } from "react";
import Nav from "../../../vendor/navbar";
import axios from "axios";
const Listing = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const user = useSelector((state) => state.user);
  const role = useSelector((state) => state.role);
  const privilege = useSelector((state) => state.myPrivileges);
  const [status, setStatus] = useState(null);

  return (
    <>
      <div className="bg-[#D9EFED] min-h-screen">
        <Nav />
        <div className="flex items-center justify-center bg-[#D9EFED] ">
          <div className="w-[95%] mt-[1rem] sm:px-10 px-4 rounded-md mb-12 ">
            <div className="cursor-pointer flex w-full items-center justify-center">
              <img
                onClick={() => {
                  if (user === "vendor-owner") {
                    router("/VendorDashboard");
                    return;
                  }

                  router("/SuperAdmin/InventoryDashboard");
                }}
                className="sm:w-[1.7rem] w-[1.3rem]"
                src={back5}
                alt=""
              />
              <h2 className="font-bold w-full sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                Orders
              </h2>
            </div>

            {user === "employee" && !privilege?.includes("Buyer") ? null : (
              <div className="flex justify-end mt-4 gap-3 flex-col-reverse sm:flex-row">
                <button
                  type="button"
                  onClick={() => {
                    dispatch({ type: "PLACE_ORDER_CHECK", num: true });
                    // router("/Inventory-Categories");
                    // router("/SuperAdmin/Inventory/SelectVendor")
                    router("/Inventory/SelectProgram");
                  }}
                  className={`${"cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[12rem]"}`}
                >
                  Place Order
                </button>
              </div>
            )}

            <div className="bg-[#fafaf9] mt-3 rounded-md p-2">
              <Cards />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Listing;
